import {
  FETCH_COINS_LIST,
  COINS_LIST_ERROR,
  CREATE_STAR,
} from "../actions/types";

const initialState = {
  coinsList: [],
  star: null,
  coinsListError: null,
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_COINS_LIST:
      return {
        ...state,
        coinsList: action.payload,
      };
    case CREATE_STAR:
      return {
        ...state,
        star: action.payload,
      };
    case COINS_LIST_ERROR: 
      return {
        ...state,
        coinsListError: action.payload,
      };
    default:
      return state;
  }
}