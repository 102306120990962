import { GET_INDICATORS, UPDATE_INDICATORS } from "../actions/types";

const initialState = {
  indicators: [],
  message: "",
  // indicatorTimes: [],
  // indicatorSeries: [],
  // updatedAt: '',
  // hoursSinceUpdate: 0,
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_INDICATORS:
      return {
        ...state,
        indicators: action.payload.indicators,
        // indicatorTimes: action.payload.indicatorTimes,
        // indicatorSeries: action.payload.indicatorSeries,
        // updatedAt: action.payload.updatedAt,
        // hoursSinceUpdate: action.payload.hoursSinceUpdate,
      };
    case UPDATE_INDICATORS:
      return {
        ...state,
        message: action.payload.message,
      };
    default:
      return state;
  }
}

