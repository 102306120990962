import React from 'react';

const DeleteForm = (props) => {
    return (
        <div>
            <h4>Are you sure you want to delete this badge <span style={{ color: "red" }}>{props.badge}</span></h4>
        </div>
    )
}

export default DeleteForm;
