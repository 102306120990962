import React from "react";
import {Alert, Button, Input, Select} from 'antd';

const Option = Select.Option;

const InviteForm = (props) => {
    const {
        name,
        email,
        password,
        isAuthor,
        isVideoCreator,
        setPassword,
        setName,
        setVideoCreator,
        setAuthor,
        setEmail,
        formError,
        inviteUserError
    } = props;

    const handleInputChange = (e, type) => {
        const val = e.target.value;
        if (type === 'name'){
            setName(val);
        }

        if (type === 'email'){
            setEmail(val);
        }

        if (type === 'password'){
            setPassword(val);
        }
    }

    const handleSelectChange = (value, type) => {
        if (type === "isAuthor"){
            setAuthor(value);
        }

        if (type === "isVideoCreator"){
            setVideoCreator(value)
        }
    }

    const generatePassword = () => {
        const randomstring = Math.random().toString(36).slice(-8);
        setPassword(randomstring)
    }

    return (
        <div>
            {
                formError || inviteUserError ?
                    <div style={{marginBottom: 20}}>
                        { formError ? <div>
                            <Alert message={formError} type="error" />
                        </div> : null}

                        { inviteUserError ? <div style={{marginTop: 5}}>
                            <Alert message={inviteUserError} type="error" />
                        </div> : null}
                    </div> : null
            }

            <div>
                <Button onClick={generatePassword}>Generate random password</Button>
            </div>

            <div style={{marginTop: 15}}>
                <label>Name</label>
                <Input
                    value={name}
                    defaultValue={name}
                    onChange={(e) => handleInputChange(e, 'name')}
                />
            </div>

            <div style={{marginTop: 15}}>
                <label>Email</label>
                <Input
                    value={email}
                    defaultValue={email}
                    onChange={(e) => handleInputChange(e, 'email')}
                />
            </div>

            <div style={{marginTop: 15}}>
                <label>Password</label>
                <Input
                    value={password}
                    defaultValue={password}
                    onChange={(e) => handleInputChange(e, 'password')}
                />
            </div>

            <div style={{marginTop: 25}}>
                <label>Author</label>
                <Select
                    value={isAuthor}
                    defaultValue={isAuthor}
                    style={{ width: '100%' }}
                    placeholder="true"
                    onChange={(val) => handleSelectChange(val, "isAuthor")}
                >
                    <Option value="true">true</Option>
                    <Option value="false">false</Option>
                </Select>
            </div>

            <div style={{marginTop: 25}}>
                <label>Video Creator</label>
                <Select
                    value={isVideoCreator}
                    defaultValue={isVideoCreator}
                    style={{ width: '100%' }}
                    placeholder="true"
                    onChange={(val) => handleSelectChange(val, "isVideoCreator")}
                >
                    <Option value="true">true</Option>
                    <Option value="false">false</Option>
                </Select>
            </div>
        </div>
    )
}

export default InviteForm;
