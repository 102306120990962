import React from "react";
import { Input, Select, Upload, Alert, DatePicker } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import YouTube from "react-youtube";
import { beforeThumbnailUpload } from "../../../utils/uploadFileToFirebase";

const Option = Select.Option;

const Form = (props) => {
  const {
    formError,
    name,
    publisher,
    handleInputChange,
    videoId,
    changeThumbnail,
    videoLink,
    thumbnailURL,
    difficulty,
    tags,
    tagIds,
    time,
    date,
    pdfs,
    pdfIds,
    // videos,
    // recommendations,
    handleSelectChanger,
    description,
  } = props;

  const opts = {
    playerVars: {
      autoplay: 0,
    },
  };

  return (
    <div>
      <div>
        {formError ? (
          <div>
            <Alert type="error" message={formError} />
          </div>
        ) : null}
        <div className="preview-container">
          <label>Preview</label>
          <YouTube videoId={videoId} opts={opts} className="preview-player" />
        </div>

        <div>
          <label>Name</label>
          <Input
            value={name}
            defaultValue={name}
            onChange={(e) => handleInputChange(e, "name")}
          />
        </div>

        <div style={{ marginTop: 15 }}>
          <label>Publisher</label>
          <Input
            value={publisher}
            defaultValue={publisher}
            onChange={(e) => handleInputChange(e, "publisher")}
          />
        </div>

        <div style={{ marginTop: 15 }}>
          <label>Description</label>
          <Input.TextArea
            rows={4}
            placeholder="Enter description here"
            value={description}
            defaultValue={description}
            onChange={(e) => handleInputChange(e, "description")}
          />
        </div>

        <div style={{ marginTop: 15 }}>
          <label>Video Link</label>
          <Input
            value={videoLink}
            defaultValue={videoLink}
            onChange={(e) => handleInputChange(e, "videoLink")}
          />
        </div>

        <div style={{ marginTop: 15 }}>
          <label>Thumbnail</label>
          <div className="thumbnail-container">
            <ImgCrop
              grid
              rotate
              aspect={16 / 9}
              quality={0.8}
              crop={{ x: 0, y: 0 }}
            >
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={beforeThumbnailUpload}
                onChange={changeThumbnail}
              >
                {thumbnailURL ? (
                  <img
                    src={thumbnailURL}
                    alt="avatar"
                    style={{ width: "100%", height: "250px" }}
                  />
                ) : (
                  <PlusOutlined />
                )}
              </Upload>
            </ImgCrop>
          </div>
        </div>

        <div style={{ marginTop: 25 }}>
          <label>Difficulty</label>
          <Select
            value={difficulty}
            defaultValue={difficulty}
            style={{ width: "100%" }}
            placeholder="Inciante"
            onChange={(val) => handleSelectChanger(val, "difficulty")}
          >
            <Option value="Iniciante">Iniciante</Option>
            <Option value="Médio">Médio</Option>
            <Option value="Avançado">Avançado</Option>
          </Select>
        </div>

        <div style={{ marginTop: 15 }}>
          <label>Tags</label>
          <Select
            allowClear
            value={tagIds}
            defaultValue={tagIds}
            mode="tags"
            style={{ width: "100%" }}
            placeholder="tags"
            onChange={(val) => handleSelectChanger(val, "tags")}
          >
            {tags && tags.length > 0
              ? tags.map((tag, idx) => {
                  return (
                    <Option value={tag.id} key={tag.id}>
                      {tag.name}
                    </Option>
                  );
                })
              : null}
          </Select>
        </div>

        <div style={{ marginTop: 15 }}>
          <label>PDFs</label>
          <Select
            allowClear
            mode="multiple"
            value={pdfIds}
            defaultValue={pdfIds}
            style={{ width: "100%" }}
            placeholder=""
            onChange={(val) => handleSelectChanger(val, "isPdfs")}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {pdfs && pdfs.length > 0
              ? pdfs.map((pdf, idx) => {
                  return (
                    <Option value={pdf.id} key={pdf.id}>
                      {pdf.name}
                    </Option>
                  );
                })
              : null}
          </Select>
        </div>

        {/* <div style={{ marginTop: 15 }}> */}
        {/*   <label>Recommendations</label> */}
        {/*   <Select */}
        {/*     allowClear */}
        {/*     mode="multiple" */}
        {/*     value={recommendations} */}
        {/*     defaultValue={recommendations} */}
        {/*     style={{ width: "100%" }} */}
        {/*     placeholder="" */}
        {/*     onChange={(val) => handleSelectChanger(val, "recommendations")} */}
        {/*     filterOption={(input, option) => */}
        {/*       option.children.toLowerCase().includes(input.toLowerCase()) */}
        {/*     } */}
        {/*   > */}
        {/*     {videos && videos.length > 0 */}
        {/*       ? videos.map((vid, idx) => { */}
        {/*           return ( */}
        {/*             <Option value={vid.id} key={vid.id}> */}
        {/*               {vid.name} */}
        {/*             </Option> */}
        {/*           ); */}
        {/*         }) */}
        {/*       : null} */}
        {/*   </Select> */}
        {/* </div> */}

        <div style={{ marginTop: 15 }}>
          <label>Date</label>
          <DatePicker
            value={date}
            defaultValue={date}
            onChange={(e) => handleInputChange(e, "date")}
            style={{ width: "100%" }}
          />
        </div>

        <div style={{ marginTop: 15 }}>
          <label>Time</label>
          <Input
            value={time}
            defaultValue={time}
            onChange={(e) => handleInputChange(e, "time")}
          />
        </div>
      </div>
    </div>
  );
};

export default Form;
