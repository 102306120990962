import React, { useEffect, useState } from "react";
import { Table, Modal, Row, Col, Button, Alert, Input, Tag } from "antd";
import Form from "./components/Form";
import "./video.scss";
import {
  createVideo,
  editVideo,
  deleteVideo,
  getVideos,
} from "../../store/actions/videoActions";
import { connect } from "react-redux";
import DeleteForm from "./components/DeleteForm";
import VideoSelectScreen from "./components/VideoSelectScreen";
import VideoForm from "./components/VideoForm";
import {
  uploadLargeFileHelper,
  uploadOtherFilesToFirebase,
} from "../../utils/uploadFileToFirebase";
import { fetchPdfs } from "../../store/actions/pdfsActions";
import { fetchTags } from "../../store/actions/tagsActions";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { searchTrack } from "../../store/actions/trackActions";
import moment from "moment";

const { Search } = Input;

// eslint-disable-next-line
const Videos = (props) => {
  const {
    createVideo,
    editVideo,
    fetchPdfs,
    fetchTags,
    pdfs,
    tags,
    getVideos,
    deleteVideo,
    videos,
    error,
    user,
    searchTrack,
    searchResult,
  } = props;
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [videoUploadPayload, setVideoUploadPayload] = useState({
    videoUploadError: null,
    videoUploadProgress: 0,
    videoUpload: null,
    videoURL: null,
    isVideoUploadInProgress: false,
  });
  const [difficulty, setDifficulty] = useState("");
  const [name, setName] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [videoId, setVideoId] = useState("");
  const [description, setDescription] = useState("");
  const [mp4url, setMp4url] = useState("");
  const [time, setTime] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [thumbnailURL, setThumbnailURL] = useState("");
  const [videoDBID, setVideoDBID] = useState("");
  const [pdfIds, setPdfIds] = useState([]);
  const [tagIds, setTagIds] = useState([]);
  const [uploadProcessInMotion, setUploadProcessInMotion] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedResults, setSearchedResults] = useState([]);
  const [modalInfo, setModalInfo] = useState({
    name: "Create Video",
    confirmBtnText: "Create",
    type: "createVideo",
  });

  useEffect(() => {
    getVideos();
    fetchPdfs();
    fetchTags();
  }, [getVideos, fetchPdfs, fetchTags]);

  useEffect(() => {
    if (
      videoUploadPayload.videoURL &&
      videoUploadPayload.videoUploadProgress === 100 &&
      uploadProcessInMotion
    ) {
      const payload = {
        name,
        path: videoUploadPayload.videoURL,
        thumbnail: thumbnailURL,
        description,
        tags: tagIds,
        difficulty,
        pdfs: pdfIds,
        uploadType: "localUpload",
      };

      if (modalInfo.type === "createLocalUploadVideo") {
        createVideo(payload).then(() => {
          clearState();
          setLoading(false);
          setIsModalVisible(false);
        });
      }

      if (modalInfo.type === "editLocalVideoUpload") {
        editVideo(payload, videoDBID).then(() => {
          clearState();
          setLoading(false);
          setIsModalVisible(false);
        });
      }
    }
  }, [
    createVideo,
    difficulty,
    description,
    name,
    pdfIds,
    tagIds,
    thumbnailURL,
    uploadProcessInMotion,
    videoUploadPayload.videoURL,
    videoUploadPayload.videoUploadProgress,
    modalInfo.type,
    editVideo,
    videoDBID,
  ]);

  useEffect(() => {
    if (searchResult && searchText) {
      setSearchedResults(searchResult.videos || []);
    }

    if (!searchText || searchText === "") {
      setSearchedResults([]);
    }
  }, [searchResult, searchText]);

  const openModal = (modalMetadata, record) => {
    setModalInfo(modalMetadata);
    setIsModalVisible(true);
    if (record) {
      setName(record ? record.name : "");
      setDifficulty(record ? record.difficulty : "");
      setDescription(record ? record.description : "");
      setMp4url(record ? record.mp4Url : "");
      setVideoLink(record ? record["path"] : "");
      setTagIds(record.tags ? record.tags.map((tag) => tag.name) : []);
      setThumbnailURL(record ? record.thumbnail : "");
      setPdfIds(record.pdfs ? record.pdfs.map((tag) => tag.id) : []);
      setVideoDBID(record ? record.id : "");
      setTime(record ? record.time : "");

      setVideoId(record ? record.videoId : "");
      setVideoUploadPayload({
        ...videoUploadPayload,
        videoURL: record ? record["path"] : "",
      });
    }

    if (modalInfo.type === "createYoutubeVideo" && record && record.videoId) {
      setVideoLink(`https://www.youtube.com/watch?v=${record.videoId}`);
    }
  };

  const handleInputChange = (e, type) => {
    if (type === "name") {
      setName(e.target.value);
    }

    if (type === "videoLink") {
      if (e.target.value) {
        let parts = e.target.value.split("/");
        const popped = parts.pop() || parts.pop();
        const id = popped.split("=")[1];
        setVideoLink(e.target.value);
        setVideoId(id);
      } else {
        setVideoLink("");
        setVideoId("");
      }
    }

    if (type === "time") {
      setTime(e.target.value);
    }

    if (type === "description") {
      setDescription(e.target.value);
    }
  };

  const handleUploadSelect = (uploadType) => {
    if (uploadType === "youtube") {
      setModalInfo({
        name: "Create Youtube Video",
        confirmBtnText: "Create",
        type: "createYoutubeVideo",
      });
    }

    if (uploadType === "localUpload") {
      setModalInfo({
        name: "Upload video from local computer",
        confirmBtnText: "Create",
        type: "createLocalUploadVideo",
      });
    }
  };

  const handleUpload = (upload) => {
    const progress = upload.file?.percent || upload.event?.percent;

    setVideoUploadPayload((prevState) => ({
      ...prevState,
      videoUpload: upload,
      videoUploadProgress: Math.trunc(progress),
    }));

    if (progress === 100) {
      uploadLargeFileHelper(
        videoUploadPayload,
        setVideoUploadPayload,
        "video",
        "videos/"
      );
    }
  };

  const handleSelectChanger = (value, selectType) => {
    if (selectType === "isPdfs") {
      setPdfIds(value);
    }

    if (selectType === "tags") {
      setTagIds(value);
    }

    if (selectType === "difficulty") {
      setDifficulty(value);
    }
  };

  const closeModal = () => {
    setIsModalVisible(false);
    clearState();
  };

  const clearState = () => {
    setName("");
    setDifficulty("");
    setVideoId("");
    setPdfIds([]);
    setTagIds([]);
    setThumbnailURL("");
    setThumbnail("");
    setTime("");
    setVideoLink("");
    setDescription("");
    setVideoDBID("");
    setUploadProcessInMotion(false);
    setVideoUploadPayload({
      videoUploadError: null,
      videoUploadProgress: 0,
      videoUpload: null,
      videoURL: null,
      isVideoUploadInProgress: false,
    });
    setMp4url("");
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const changeThumbnail = (file) => {
    getBase64(file.file.originFileObj, (imageUrl) => {
      setThumbnail(file.file);
      setThumbnailURL(imageUrl);
    });
  };

  const handleOk = async () => {
    setLoading(true);

    const payload = {
      name,
      videoId,
      time,
      thumbnail: thumbnailURL,
      description,
      tags: tagIds,
      difficulty,
      pdfs: pdfIds,
      uploadType:
        modalInfo.type === "createYoutubeVideo" ? "localUpload" : "localUpload",
    };

    if (modalInfo.type === "createLocalUploadVideo") {
      let thumbFileURL = await uploadOtherFilesToFirebase(
        thumbnail,
        "thumbnail"
      );
      setThumbnailURL(thumbFileURL);
      // setUploadProcessInMotion(true);
      // uploadLargeFileHelper(videoUploadPayload, setVideoUploadPayload, "video");
      // return false;
      
      if (name.length > 0 && 
          difficulty.length > 0 && 
          thumbFileURL.length > 0 &&
          videoUploadPayload.videoURL &&
          videoUploadPayload.videoUploadProgress === 100
        )
        await createVideo({ ...payload, thumbnail: thumbFileURL });
    }

    if (modalInfo.type === "editLocalVideoUpload") {
      if (thumbnail) {
        let thumbFileURL = await uploadOtherFilesToFirebase(
          thumbnail,
          "thumbnail"
        );
        setThumbnailURL(thumbFileURL);
        payload.thumbnail = thumbFileURL;
      }

      // if (videoUploadPayload.videoUpload) {
      //   setUploadProcessInMotion(true);
      //   uploadLargeFileHelper(
      //     videoUploadPayload,
      //     setVideoUploadPayload,
      //     "video"
      //   );
      //   return false;
      // }

      await editVideo(payload, videoDBID);
    }

    if (modalInfo.type === "createYoutubeVideo") {
      let thumbFileURL = await uploadOtherFilesToFirebase(
        thumbnail,
        "thumbnail"
      );
      if (name.length > 0 && difficulty.length > 0 && thumbFileURL.length > 0)
        await createVideo({ ...payload, thumbnail: thumbFileURL });
    }

    if (modalInfo.type === "editYoutubeVideo") {
      let thumbFileURL;
      if (thumbnail) {
        thumbFileURL = await uploadOtherFilesToFirebase(thumbnail, "thumbnail");
      } else {
        thumbFileURL = thumbnailURL;
      }
      await editVideo({ ...payload, thumbnail: thumbFileURL }, videoDBID);
    }

    if (modalInfo.type === "deleteVideo") {
      await deleteVideo(videoDBID);
    }

    setLoading(false);
    setIsModalVisible(false);
    clearState();
    await getVideos();
  };

  const onSearch = async (value) => {
    if (value) {
      await searchTrack(value);
    }
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Difficulty",
      dataIndex: "difficulty",
      key: "difficulty",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.difficulty.localeCompare(b.difficulty),
    },
    {
      title: "Transcoding",
      dataIndex: "transcoding",
      key: "transcoding",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.transcoding.localeCompare(b.is_ready),
      render: (_, record) => (
        <Tag color={record.is_ready === true ? "green" : "red"}>
          {`${!record.is_ready}`}
        </Tag>
      ),
    },
    {
      title: "CreatedAt",
      dataIndex: "createdAt",
      key: "createdAt",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: (_, record) => (
        <div>{moment(record?.createdAt).format("DD/MMM/YYYY")}</div>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (_, record) => (
        <div>
          <Row gutter={4}>
            {record.createdBy === user.email ||
            user.clearanceLevel === "admin" ? (
              <Col>
                <Button
                  type="primary"
                  onClick={() =>
                    openModal(
                      {
                        name: "Edit video",
                        confirmBtnText: "Update",
                        type: record.videoId
                          ? "editYoutubeVideo"
                          : "editLocalVideoUpload",
                      },
                      record
                    )
                  }
                >
                  <EditOutlined />
                </Button>
              </Col>
            ) : null}

            {user.clearanceLevel === "admin" ? (
              <Col>
                <Button
                  danger
                  onClick={() =>
                    openModal(
                      {
                        name: `Delete this video(${record.name})`,
                        confirmBtnText: "Delete",
                        type: "deleteVideo",
                      },
                      record
                    )
                  }
                >
                  <DeleteOutlined />
                </Button>
              </Col>
            ) : null}
          </Row>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div>
        <Row gutter={4}>
          <Col>
            {user.clearanceLevel === "admin" || user.isVideoCreator ? (
              <Button
                type="primary"
                onClick={() =>
                  openModal({
                    name: "Select type of upload",
                    confirmBtnText: "Confirm",
                    type: "selectTypeofUpload",
                  })
                }
              >
                Create Video
              </Button>
            ) : null}
          </Col>

          <Col>
            <Search
              value={searchText}
              placeholder="Search here.."
              onSearch={onSearch}
              style={{ width: 200 }}
              onChange={handleSearchChange}
            />
          </Col>
        </Row>
      </div>

      {error ? (
        <div style={{ marginTop: 20 }}>
          {" "}
          <Alert message={error} type="error" />{" "}
        </div>
      ) : (
        ""
      )}

      <div style={{ marginTop: 20 }}>
        <Table
          columns={columns}
          dataSource={
            searchText && searchedResults.length > 0
              ? searchedResults
              : Array.isArray(videos)
              ? videos
              : []
          }
        />
      </div>

      <Modal
        title={modalInfo.name}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={closeModal}
        footer={[
          <Button key="back" onClick={closeModal}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            danger={modalInfo.type === "deleteVideo"}
            loading={loading}
            onClick={handleOk}
          >
            {modalInfo.confirmBtnText}
          </Button>,
        ]}
      >
        {modalInfo.type === "selectTypeofUpload" ? (
          <VideoSelectScreen handleUploadSelect={handleUploadSelect} />
        ) : null}
        {modalInfo.type === "createLocalUploadVideo" ||
        modalInfo.type === "editLocalVideoUpload" ? (
          <VideoForm
            difficulty={difficulty}
            name={name}
            tags={tags}
            tagIds={tagIds}
            videoLink={videoLink}
            videoId={videoId}
            time={time}
            thumbnail={thumbnail}
            handleInputChange={handleInputChange}
            changeThumbnail={changeThumbnail}
            thumbnailURL={thumbnailURL}
            handleSelectChanger={handleSelectChanger}
            pdfIds={pdfIds}
            pdfs={pdfs}
            videos={videos || []}
            handleUpload={handleUpload}
            videoUploadPayload={videoUploadPayload}
            description={description}
            mp4url={mp4url}
          />
        ) : null}
        {modalInfo.type === "createYoutubeVideo" ||
        modalInfo.type === "editYoutubeVideo" ? (
          <Form
            difficulty={difficulty}
            name={name}
            tags={tags}
            tagIds={tagIds}
            videoLink={videoLink}
            videoId={videoId}
            time={time}
            thumbnail={thumbnail}
            handleInputChange={handleInputChange}
            changeThumbnail={changeThumbnail}
            thumbnailURL={thumbnailURL}
            handleSelectChanger={handleSelectChanger}
            pdfIds={pdfIds}
            pdfs={pdfs}
            videos={videos || []}
            description={description}
            mp4url={mp4url}
          />
        ) : null}
        {modalInfo.type === "deleteVideo" ? <DeleteForm name={name} /> : null}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  response: state.videos.video || [],
  videos: state.videos.videos || [],
  error: state.videos.error,
  pdfs: state.pdfs.pdfs,
  tags: state.tags.tags,
  searchResult: state.tracks.searchResult,
  searchError: state.tracks.searchError,
});

export default connect(mapStateToProps, {
  createVideo,
  editVideo,
  deleteVideo,
  getVideos,
  fetchPdfs,
  fetchTags,
  searchTrack,
})(Videos);
