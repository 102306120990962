import { GET_AUTHORS, GET_USERS, ADD_AUTHOR, DELETE_AUTHOR } from "./types";

export const getUsers = () => async (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();

  const res = await firestore.collection("users").get();

  const users = res.docs.map(doc => ({ id: doc.id, ...doc.data() }));

  dispatch({ type: GET_USERS, payload: users });
};

export const getAuthors = () => async (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();

  const res = await firestore.collection("authors").get();

  const authors = res.docs.map(doc => ({ id: doc.id, ...doc.data() }));

  dispatch({ type: GET_AUTHORS, payload: authors });
};

export const deleteAuthor = id => async (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();

  await firestore
    .collection("authors")
    .doc(id)
    .delete();

  dispatch({ type: DELETE_AUTHOR, payload: id });
};

export const createAuthors = userID => async (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();

  const userDoc = await firestore
    .collection("users")
    .doc(userID)
    .get();
  const userData = userDoc.data();

  await firestore
    .collection("authors")
    .doc(userDoc.id)
    .set({
      ...userData
    });

  dispatch({
    type: ADD_AUTHOR,
    payload: {
      ...userData
    }
  });
};
