import React from "react";
import { Card, Col, Row } from "antd";

const VideoSelectScreen = ({ handleUploadSelect }) => {
  return (
    <div>
      <Row gutter={16}>
        <Col span={12}>
          <Card
            onClick={() => handleUploadSelect("localUpload")}
            style={{
              cursor: "pointer",
              background: "red",
              color: "white",
              textAlign: "center",
            }}
          >
            <span>Local Upload</span>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default VideoSelectScreen;

