import {
  GET_AUTHORS,
  GET_USERS,
  ADD_AUTHOR,
  DELETE_AUTHOR,
} from "../actions/types";

const initialState = {
  authors: [],
  users: [],
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_AUTHORS:
      return { ...state, authors: action.payload };
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case ADD_AUTHOR:
      return {
        ...state,
        authors: [action.payload, ...state.authors],
      };
    case DELETE_AUTHOR:
      return {
        ...state,
        authors: state.authors.filter((author) => author.id !== action.payload),
      };
    default:
      return state;
  }
}
