import { GET_COUPONS, GET_COUPONS_BY_CODE } from "../actions/types";

const initialState = {
  coupons: [],
  coupons_filter: [],
  currentCoupon: null,
  error: null,
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_COUPONS:
      return {
        ...state,
        coupons: action.payload,
      };
    case GET_COUPONS_BY_CODE:
      return {
        ...state,
        coupons_filter: action.payload,
      };
    default:
      return state;
  }
}
