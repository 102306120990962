import React, { useState, useEffect } from "react";
import { Table, Row, Col, Spin, Button, Modal, Input } from "antd";
import { connect } from "react-redux";
import { getWeb3, createWeb3, deleteWeb3, } from "../../store/actions/web3Actions";
import { DeleteOutlined } from "@ant-design/icons";
import Web3Form from "./components/Web3Form";
import Web3Delete from "./components/Web3Delete";

const { Search } = Input;

const Web3 = ({ 
  web3List, 
  web3Error, 
  getWeb3, 
  createWeb3, 
  deleteWeb3, 
  user,
}) => {
  const data = [];
  const bonus = [
    {
      name: "Quadri-Light",
      id: "2",
    },
    {
      name: "Anual-Light",
      id: "3",
    },
    {
      name: "Pro-Quadri",
      id: "4",
    },
    {
      name: "Pro-Anual",
      id: "5",
    },
  ];

  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [bonusIds, setBonusIds] = useState([]);
  const [collectionId, setCollectionId] = useState(null);
  const [name, setName] = useState("");
  const [contract, setContract] = useState("");
  const [network, setNetwork] = useState("");
  const [condition, setCondition] = useState("");
  const [modalInfo, setModalInfo] = useState({
    name: "Create Web3",
    confirmBtnText: "Confirm",
    type: "createWeb3",
  });

  useEffect(() => {
    getWeb3();
  }, [getWeb3]);

  const openModal = (modalMetadata, record) => {
    setModalInfo(modalMetadata);
    setIsModalVisible(true);
    if (record) {
      // setBonusIds(record.bonus ? record.bonus.map((tag) => tag.id) : []);
      setCollectionId(record._id ? record._id : null);
      setName(record ? record.name : "");
      setCondition(record ? record.condition : "");
      setContract(record ? record.contract : "");
      setNetwork(record ? record.network : "");
    }
  };

  const clearState = () => {
    // setBonusIds([]);
    setName([]);
    setCondition([]);
    setContract([]);
    setNetwork([]);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    clearState();
  };

  const handleOk = async () => {
    setLoading(true);

    const payload = {
      name,
      contract,
      condition,
      network,
    };

    if (modalInfo.type === "createWeb3") {
      await createWeb3(payload);
    }

    if (modalInfo.type === "deleteCollection") {
      await deleteWeb3(collectionId);
    }

    setLoading(false);
    setIsModalVisible(false);
    clearState();
    await getWeb3();
  };

  // const handleSelectChanger = (value, selectType) => {
  //   if (selectType === "isBonus") {
  //     setBonusIds(value);
  //   }
  // };

  const handleInputChange = (event, eventType) => {
    if (eventType === "name") {
        setName(event.target.value);
    }

    if (eventType === "contract") {
      setContract(event.target.value);
    }

    if (eventType === "bonus") {
      setCondition(event.target.value);
    }

    if (eventType === "network") {
      setNetwork(event.target.value);
    }

  }

  const getBonus = (id) => {
    const item = bonus.find(obj => obj.id === id);
    return item ? item.name : "ID not found";
  }

  const columns = [
    {
      title: "Id",
      dataIndex: "_id",
      key: "_id",
    },
    {
      title: "Collection",
      dataIndex: "name",
      key: "name",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Bonus",
      dataIndex: "condition",
      key: "condition",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.bonus.localeCompare(b.bonus),
      render: (_, record) => getBonus(record.condition),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (_, record) => (
        <div>
          <Row gutter={4}>
            {/* {record.createdBy === user.email ||
            user.clearanceLevel === "admin" ? (
              <Col>
                <Button
                  type="primary"
                  onClick={() =>
                    openModal(
                      {
                        name: "Bonus rule",
                        confirmBtnText: "Confirm",
                        type: "bonusRule",
                      },
                      record
                    )
                  }
                >
                  <EditOutlined />
                </Button>
              </Col>
            ) : null} */}
            {record.createdBy === user.email ||
            user.clearanceLevel === "admin" ? (
              <Col>
                <Button
                  danger
                  onClick={() =>
                    openModal(
                      {
                        name: `Delete this Collection(${record.name})`,
                        confirmBtnText: "Delete",
                        type: "deleteCollection",
                      },
                      record
                    )
                  }
                >
                  <DeleteOutlined />
                </Button>
              </Col>
            ) : null}
          </Row>
        </div>
      ),
    },
  ];

  return (
    <>
      <div>
        <div>
          <Row gutter={4}>
            <Col>
              {user.clearanceLevel === "admin" ? (
                <Button
                  type="primary"
                  onClick={() =>
                    openModal({
                      name: "Create Web3",
                      confirmBtnText: "Confirm",
                      type: "createWeb3",
                    })
                  }
                >
                  Create Video
                </Button>
              ) : null}
            </Col>

            <Col>
              <Search
                // value={searchText}
                placeholder="Search here.."
                // onSearch={onSearch}
                style={{ width: 200 }}
                // onChange={handleSearchChange}
              />
            </Col>
          </Row>
        </div>

        {/* {error ? (
          <div style={{ marginTop: 20 }}>
            {" "}
            <Alert message={error} type="error" />{" "}
          </div>
        ) : (
          ""
        )} */}

        <div style={{ marginTop: 20 }}>
          <Spin spinning={loading}>
            <Table columns={columns} dataSource={web3List} />
          </Spin>
        </div>

        <Modal
          title={modalInfo.name}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={closeModal}
          footer={[
            <Button key="back" onClick={closeModal}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={handleOk}
            >
              {modalInfo.confirmBtnText}
            </Button>,
          ]}
        >
          {modalInfo.type === "createWeb3" ? (
            <Web3Form
              bonus={condition}
              name={name}
              contract={contract}
              network={network}
              data={data || []}
              // handleSelectChanger={handleSelectChanger}
              handleInputChange={handleInputChange}
              web3Error={web3Error}
            />
          ) : null}
          {modalInfo.type === "deleteCollection" ? <Web3Delete /> : null}
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  web3List: state.web3List.web3List || [],
  web3: state.web3List.web3 || [],
  web3Error: state.web3List.trackError,
});

export default connect(mapStateToProps, {
  getWeb3,
  createWeb3,
  deleteWeb3,
})(Web3);

