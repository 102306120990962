import {
  GET_GRAFICOSS,
  GET_GRAFICOS,
  ADD_GRAFICOS,
  DELETE_GRAFICOS,
  UPDATE_GRAFICOS
} from "./types";
import history from "../../history";

export const getGraficos = () => async (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();
  const res = await firestore.collection("graficos").get();
  const graficos = res.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  dispatch({
    type: GET_GRAFICOSS,
    payload: graficos
  });
};

export const getGRAFICOS = id => async (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();

  const doc = await firestore
    .collection("graficos")
    .doc(id)
    .get();

  const graficos = { id: doc.id, ...doc.data() };
  dispatch({
    type: GET_GRAFICOS,
    payload: graficos
  });
};

export const createGraficos = data => async (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();

  const doc = await firestore.collection("graficos").add(data);

  const newGraficos = await firestore
    .collection("graficos")
    .doc(doc.id)
    .get();
  const GRAFICOS = { id: doc.id, ...newGraficos.data() };

  dispatch({
    type: ADD_GRAFICOS,
    payload: GRAFICOS
  });
  history.push("/graficos");
};

export const updateGraficos = (id, updGrafico) => async (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();

  await firestore
    .collection("graficos")
    .doc(id)
    .update(updGrafico);

  const newGraficos = await firestore
    .collection("graficos")
    .doc(id)
    .get();
  const grafico = { id, ...newGraficos.data() };

  dispatch({
    type: UPDATE_GRAFICOS,
    payload: grafico
  });
  history.push("/graficos");
};

export const deleteGraficos = id => async (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();

  await firestore
    .collection("graficos")
    .doc(id)
    .delete();

  dispatch({
    type: DELETE_GRAFICOS,
    payload: id
  });
};
