import React from "react";

const DeleteForm = (props) => {
  return (
    <div>
      <h4>
        Are you sure you want to delete this short link{" "}
        <span style={{ color: "red" }}>{props.shortLink}</span>
      </h4>
    </div>
  );
};

export default DeleteForm;
