import React from "react";
import { Button } from "antd";

const NotFound = () => {
  const url =
    "https://cdn-icons.flaticon.com/png/512/1156/premium/1156412.png?token=exp=1647178714~hmac=26bea6197c671d5f39969d83f94b2755";
  return (
    <div>
      <Button type="primary" href="/">
        Back to Homepage
      </Button>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginTop: 20,
        }}
      >
        <img src={url} alt="foo"/>
      </div>
    </div>
  );
};

export default NotFound;

