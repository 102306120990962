import React, { useState } from "react";
import { connect } from "react-redux";

import { createGraficos } from "../../store/actions/graficosActions";
import { uploadToFirebase } from "../../store/actions/postsActions";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Select,
  Spin,
  Upload,
} from "antd";
import { SmileOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";

const { Option } = Select;

const NewGraficos = ({
  form: nulll,
  createPost,
  getAuthors,
  authors,
  createGraficos
}) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [coinImg, setCoinImg] = useState(false);
  const [fields, setFields] = useState([
    {
      name: 'ativoFull',
      value: '',
    },
    {
      name: 'ativoAbr',
      value: '',
    },
    {
      name: 'ponta',
      value: '',
    },
    {
      name: 'modelo',
      value: '',
    },
    {
      name: 'modeloText',
      value: '',
    },
    {
      name: 'modeloURL',
      value: '',
    },
    {
      name: 'timeFrame',
      value: '',
    },
  ]);

  const handleSubmit = async (values) => {
    setLoading(true);
    await createGraficos({ ...values, coinImg, timeFrameDate: new Date() });
    setLoading(false);
  };
  
  const uploadCoinImage = async ({ file, onError, onProgress, onSuccess }) => {
    try {
      setLoading(true);
      const url = await uploadToFirebase(file);
      setLoading(false);
      setCoinImg(url);
      onSuccess();
    } catch (error) {
      console.log(error);
      onError();
    }
  };

  const validateFields = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
    setLoading(false);
  }

  const handleChange = (fields) => {
    setFields(fields);
  }

  return (
    <Form 
      name="global_state"
      onFinish={handleSubmit}
      fields={fields}
      onFinishFailed={validateFields}
      onFieldsChange={(_, allFields) => {
        handleChange(allFields);
      }} 
    >
      <Spin spinning={loading}>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item name="ativoFull" label="Ativo Full" rules={[{ required: true, message: "this field is required" }]}>
              <Input placeholder="Bitcoin" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="ativoAbr" label="Ativo Abr" rules={[{ required: true, message: "this field is required" }]}>
             <Input placeholder="BTC" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="ponta" label="Ponta" rules={[{ required: true, message: "this field is required" }]}>
                <Select style={{ width: "100%" }} placeholder="Please select">
                  <Option value="compra">Compra</Option>
                  <Option value="venda">Venda</Option>
                  <Option value="neutra">Neutra</Option>
                </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="modelo" label="Estratégia"  rules={[{ required: true, message: "this field is required" }]}>
                <Select style={{ width: "100%" }} placeholder="Please select">
                  <Option value="tendencia">Tendência</Option>
                  <Option value="candle">Candle</Option>
                  <Option value="rompimento">Rompimento</Option>
                  <Option value="volatilidade">Volatilidade</Option>
                </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="modeloText" label="Estratégia text" rules={[{ required: true, message: "this field is required" }]}>
             <Input placeholder="Estratégia text" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="modeloURL" label="Estratégia URL">
              <Input placeholder="Estratégia URL" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="timeFrame" label="TimeFrame" rules={[{ required: true, message: "this field is required" }]}>
              <Input placeholder="TimeFrame" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Coin Image">
              <Upload customRequest={uploadCoinImage}>
                <Button>
                  <SmileOutlined type="upload" /> Click to Upload
                </Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              disabled={loading}
              style={{
                display: "block",
                marginLeft: "auto",
                width: "100px"
              }}
            >
              Create
            </Button>
          </Col>
        </Row>
      </Spin>
    </Form>
  );
}

const mapStateToProps = state => ({
  authors: state.authors.authors
});
export default connect(mapStateToProps, { createGraficos })(NewGraficos)
