import {
  GET_GRAFICOSS,
  GET_GRAFICOS,
  ADD_GRAFICOS,
  DELETE_GRAFICOS,
  UPDATE_GRAFICOS,
} from "../actions/types";

const initialState = {
  graficos: [],
  currentGRAFICOS: null,
  error: null,
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_GRAFICOSS:
      return {
        ...state,
        graficos: action.payload,
      };
    case GET_GRAFICOS:
      return {
        ...state,
        currentGRAFICOS: action.payload,
      };
    case ADD_GRAFICOS:
      return {
        ...state,
        graficos: [...state.graficos, action.payload],
        currentGRAFICOS: action.payload,
      };
    case UPDATE_GRAFICOS:
      return {
        ...state,
        graficos: state.graficos.map((GRAFICOS) =>
          GRAFICOS.id === action.payload.id
            ? { ...GRAFICOS, ...action.payload }
            : GRAFICOS
        ),
        currentGRAFICOS: action.payload,
      };
    case DELETE_GRAFICOS:
      return {
        ...state,
        graficos: state.graficos.filter(
          (GRAFICOS) => GRAFICOS.id !== action.payload
        ),
      };
    default:
      return state;
  }
}
