import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Input, DatePicker, Select } from 'antd';

const { Option } = Select;
const CuratedForm = (props) => {
    const { payload, handlePayloadChange, response, type } = props;
    const [name, setName] = useState("");
    const [link, setLink] = useState("");
    const [startingDate, setStartingDate] = useState("");
    const [tags, setTags] = useState([]);
    const [tickers, setTickers] = useState("");
    const [description, setDescription] = useState("");
    const [isArchived, setIsArchived] = useState("false");

    useEffect(() => {
        if (type === "editProfile" && !response && payload){
            setName(payload.name);
            setStartingDate(moment(payload.startingDate));
            setTags(payload.tags);
            setDescription(payload.description);
            setTickers(payload.tickers);
            setIsArchived(payload.isArchived);
            setLink(payload.link);
        }
    }, [payload, response, type])

    useEffect(() => {
        if (response && response.message){
            setName('')
            setStartingDate('');
            setTags([]);
            setDescription('');
            setTickers('');
            handlePayloadChange({});
            setIsArchived("false");
            setLink('')
        }
    }, [handlePayloadChange, response])

    const handleChange = (e, type) => {
        if (type === 'name') {
            setName(e.target.value);
            handlePayloadChange({...payload, name: e.target.value});
        }

        if (type === 'link') {
            setLink(e.target.value);
            handlePayloadChange({...payload, link: e.target.value});
        }

        if (type === 'tickers'){
            setTickers(e.target.value);
            handlePayloadChange({...payload, tickers: e.target.value});
        }

        if (type === 'description'){
            setDescription(e.target.value);
            handlePayloadChange({...payload, description: e.target.value});
        }

        if (type === 'isArchived') {
            setIsArchived(e);
            handlePayloadChange({...payload, isArchived: e});
        }
    }

    const handleDateChange = (date, dateString) => {
        setStartingDate(moment(dateString));
        handlePayloadChange({...payload, startingDate: dateString});
    }

    const handleTagChanger = (value) => {
        setTags(value)
        handlePayloadChange({...payload, tags: value});
    }

    return (
        <div>
            <div>
                <div>
                    <label>Name</label>
                    <Input 
                        value={name}
                        defaultValue={name}
                        onChange={(e) => handleChange(e, 'name')}
                    />
                </div>

                <div>
                    <label>Link</label>
                    <Input 
                        value={link}
                        defaultValue={link}
                        onChange={(e) => handleChange(e, 'link')}
                    />
                </div>

                <div style={{marginTop: 15}}>
                    <label>Starting Date</label>
                    <DatePicker
                        onChange={handleDateChange}
                        style={{width: "100%"}}
                        value={startingDate}
                        defaultValue={startingDate}
                    />
                </div>

                <div style={{marginTop: 15}}>
                    <label>Tags</label>
                    <Select
                        value={tags}
                        defaultValue={tags}
                        mode="tags"
                        style={{ width: '100%' }}
                        placeholder="Tags Mode"
                        onChange={handleTagChanger}>
                    </Select>
                </div>

                <div style={{marginTop: 15}}>
                    <label>Archived</label>
                    <Select style={{ width: '100%' }} defaultValue={isArchived} onChange={(e) => handleChange(e, "isArchived")}>
                        <Option value="true">True</Option>
                        <Option value="false">False</Option>
                    </Select>
                </div>

                <div style={{marginTop: 15}}>
                    <label>Tickers</label>
                    <Input 
                        value={tickers}
                        defaultValue={tickers}
                        onChange={(e) => handleChange(e, "tickers")}
                    />
                </div>

                <div style={{marginTop: 15}}>
                    <label>Description</label>
                    <Input.TextArea 
                        value={description}
                        defaultValue={description} 
                        onChange={(e) => handleChange(e, "description")}
                        rows={4}
                    />
                </div>
            </div>
        </div>
    )
}

export default CuratedForm;
