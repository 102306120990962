import { FEEDBACK_ERROR, FETCH_FEEDBACK } from "../actions/types";

const initialState = {
  feedback: [],
  feedbackError: null,
};

export default function feedback(state = initialState, action) {
  switch (action.type) {
    case FEEDBACK_ERROR:
      return {
        ...state,
        feedbackError: action.payload,
      };
    case FETCH_FEEDBACK:
      return {
        ...state,
        feedback: action.payload,
      };
    default:
      return state;
  }
}
