import { GET_WEB3, CREATE_WEB3, DELETE_WEB3, WEB3_ERROR } from "./types";
import axios from "axios";

export const getWeb3 = () => async (dispatch) => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/rules`
    );

    return dispatch({
      type: GET_WEB3,
      payload: data.data.rules ? data.data.rules : data.rules,
    });
  } catch (error) {
    console.log(error);
    return dispatch({
      type: WEB3_ERROR,
      payload: error.response ? error.response.data.error : error.message,
    });
  }
};

export const createWeb3 = (payload) => async (dispatch) => {
  try {
    const data = await axios.post(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/rules`,
      payload
    );

    return dispatch({
      type: CREATE_WEB3,
      payload: data.data,
    });
  } catch (error) {
    return dispatch({
      type: WEB3_ERROR,
      payload: error.response ? error.response.data.error : error.message,
    });
  }
};

export const deleteWeb3 = (web3Id) => async (dispatch) => {
  try {
    const data = await axios.delete(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/rules/${web3Id}`
    );

    return dispatch({
      type: DELETE_WEB3,
      payload: data.data,
    });
  } catch (error) {
    return dispatch({
      type: WEB3_ERROR,
      payload: error.response ? error.response.data.error : error.message,
    });
  }
};
