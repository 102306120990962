import React from "react";
import { Alert, Input } from "antd";

// const Option = Select.Option;

const Web3Form = (props) => {
  const {
    bonus,
    name,
    contract,
    network,
    web3Error,
    handleInputChange,
    // handleSelectChanger,
  } = props;

  return (
    <div>
      {web3Error && typeof web3Error === "string" ? (
        <div style={{ marginBottom: 25 }}>
          <Alert type="error" message={web3Error} />
        </div>
      ) : null}

      <div>
        <label>Name</label>
        <Input
          value={name}
          defaultValue={name}
          onChange={(e) => handleInputChange(e, "name")}
        />
      </div>

      <div style={{ marginTop: 15 }}>
        <label>Bonus</label>
        <Input
          value={bonus}
          defaultValue={bonus}
          onChange={(e) => handleInputChange(e, "bonus")}
        />
      </div>

      <div style={{ marginTop: 15 }}>
        <label>Contract</label>
        <Input
          value={contract}
          defaultValue={contract}
          onChange={(e) => handleInputChange(e, "contract")}
        />
      </div>

      <div style={{ marginTop: 15 }}>
        <label>Network</label>
        <Input
          value={network}
          defaultValue={network}
          onChange={(e) => handleInputChange(e, "network")}
        />
      </div>


      {/* <div>
        <label>Bonus Rule</label>
        <Select
          allowClear
          mode="multiple"
          value={bonusIds}
          defaultValue={bonusIds}
          style={{ width: "100%" }}
          placeholder=""
          onChange={(val) => handleSelectChanger(val, "isBonus")}
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {bonus && bonus.length > 0
            ? bonus.map((el, idx) => {
                return (
                  <Option value={el.id} key={el.id}>
                    {el.name}
                  </Option>
                );
              })
            : null}
        </Select>
      </div> */}
    </div>
  );
};

export default Web3Form;