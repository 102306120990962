import React from "react";
import { Alert, Input, Select } from "antd";

const Option = Select.Option;

const CreateUpdateForm = ({
  shortLinkError,
  handleInputChange,
  name,
  url,
  posts,
}) => {
  return (
    <div>
      {shortLinkError && typeof shortLinkError === "string" ? (
        <div>
          {shortLinkError && typeof shortLinkError === "string" ? (
            <div>
              <Alert type="error" message={shortLinkError} />
            </div>
          ) : null}

          <div style={{ marginTop: 15 }}>
            <label>Post</label>
            <Select
              allowClear
              value={name}
              style={{ width: "100%" }}
              placeholder="Select a post"
              optionFilterProp="children"
              onChange={(e) => handleInputChange(e, "destination")}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {posts && posts.length > 0
                ? posts.map((item) => {
                    return (
                      <Option value={item.id} key={item.id}>
                        {item.title}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </div>

          <div style={{ marginTop: 15 }}>
            <label>URL</label>
            <Input
              value={url}
              defaultValue={url}
              onChange={(e) => handleInputChange(e, "url")}
            />
          </div>
        </div>
      ) : null}

      <div style={{ marginTop: 15 }}>
        <label>Post</label>
        <Select
          showSearch
          placeholder="Select a post"
          value={name}
          style={{ width: "100%" }}
          onChange={(e) => handleInputChange(e, "destination")}
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {posts && posts.length > 0
            ? posts.map((item) => {
                return (
                  <Option value={item.id} key={item.id}>
                    {item.title}
                  </Option>
                );
              })
            : null}
        </Select>
      </div>

      <div style={{ marginTop: 15 }}>
        <label>URL</label>
        <Input
          value={url}
          defaultValue={url}
          onChange={(e) => handleInputChange(e, "url")}
        />
      </div>
    </div>
  );
};

export default CreateUpdateForm;
