import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Modal,
  Row,
  Table,
  Avatar,
  message,
  Input,
} from "antd";
import CreateUpdateForm from "./CreateUpdateForm";
import DeleteForm from "./DeleteForm";
import { connect } from "react-redux";
import {
  getBase64,
  uploadOtherFilesToFirebase,
} from "../../utils/uploadFileToFirebase";
import {
  createPdf,
  deletePdf,
  updatePdf,
  fetchPdfs,
} from "../../store/actions/pdfsActions";
import { FilePdfOutlined } from "@ant-design/icons";
import { searchTrack } from "../../store/actions/trackActions";

const { Search } = Input;

const Pdfs = ({
  pdfs,
  fetchPdfs,
  updatePdf,
  deletePdf,
  createPdf,
  pdfError,
  searchResult,
  searchTrack,
}) => {
  const [formError] = useState("");
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("createPdf");
  const [name, setName] = useState("");
  const [kind, setKind] = useState("");
  const [url, setUrl] = useState("");
  const [pdfUrl, setPdfsUrl] = useState(null);
  const [pdfUpload, setPdfUpload] = useState(null);
  const [pdfId, setPdfId] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailURL, setThumbnailURL] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedResults, setSearchedResults] = useState([]);

  useEffect(() => {
    fetchPdfs();
  }, [fetchPdfs]);

  useEffect(() => {
    if (searchResult && searchText) {
      setSearchedResults(searchResult.pdfs || []);
    }

    if (!searchText || searchText === "") {
      setSearchedResults([]);
    }
  }, [searchResult, searchText]);

  const openModal = (modalType, record) => {
    setIsModalVisible(true);
    setType(modalType);
    if (record) {
      setKind(record.kind);
      setName(record.name);
      setUrl(record.url);
      setThumbnailURL(record.thumbnail);
      setPdfId(record.id);
      setPdfsUrl(record.url);
    }
  };

  const clearFormData = () => {
    setName("");
    setPdfUpload(null);
    setPdfsUrl(null);
    setThumbnailURL("");
    setThumbnail(null);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    clearFormData();
  };

  const handleOk = async () => {
    setLoading(true);
    let fileURL;
    if (type === "createPdf" && name) {
      if (pdfUpload) {
        fileURL = await uploadOtherFilesToFirebase(pdfUpload.file, "pdf");
      }
      let fileImg = "";
      if (thumbnail) {
        fileImg = await uploadOtherFilesToFirebase(thumbnail.file, "thumbnail");
      }
      const finalPayload = {
        name,
        kind,
        url: url !== "" ? url : fileURL,
        thumbnail: fileImg,
      };
      await createPdf(finalPayload);
    }

    if (type === "editPdf" && name) {
      let fileURL;
      let fileImg = "";
      if (thumbnail) {
        fileImg = await uploadOtherFilesToFirebase(thumbnail.file, "thumbnail");
      } else {
        fileImg = thumbnailURL;
      }
      if (pdfUpload && pdfUrl) {
        fileURL = await uploadOtherFilesToFirebase(pdfUpload.file, "pdf");
      } else {
        fileURL = pdfUrl;
      }
      const finalPayload = {
        name,
        kind,
        url: fileURL,
        thumbnail: fileImg,
      };
      await updatePdf(finalPayload, pdfId);
    }

    if (type === "deletePdf") {
      await deletePdf(pdfId);
    }

    setLoading(false);
    clearFormData();
    closeModal();
    await fetchPdfs();
  };

  const handleUpload = (upload) => {
    setPdfUpload(upload);
  };

  const beforeUpload = (file) => {
    const isPDF = file.type === "application/pdf";
    if (!isPDF) {
      message.error("You can only upload pdf files");
      return false;
    }
    const isLt5MB = file.size <= 20000000;
    if (!isLt5MB) {
      message.error("PDF file must be smaller than 20 MB!");
      return false;
    }
    return isPDF && isLt5MB;
  };

  const handleSelectChange = (value) => {
    setKind(value);
  };

  const handleInputChange = (event, eventType) => {
    if (eventType === "name") {
      setName(event.target.value);
    }
    if (eventType === "url") {
      setUrl(event.target.value);
    }
  };

  const handleUploadImg = (upload) => {
    getBase64(upload.file.originFileObj, (imageUrl) => {
      setThumbnail(upload);
      setThumbnailURL(imageUrl);
    });
  };

  const onSearch = async (value) => {
    if (value) {
      await searchTrack(value);
    }
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const columns = [
    {
      title: "Pdf",
      dataIndex: "url",
      key: "url",
      render: (_, record) => (
        <div>
          <Avatar size="small" shape="square" icon={<FilePdfOutlined />} />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "CreatedAt",
      dataIndex: "createdAt",
      key: "createdAt",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
    {
      title: "Kind",
      dataIndex: "kind",
      key: "kind",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => new Date(a.kind) - new Date(b.kind),
    },
    {
      title: "Url",
      dataIndex: "url",
      key: "url",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (_, record) => (
        <div>
          <Row gutter={4}>
            <Col>
              <Button
                href={record.url}
                target="_blank"
                type="default"
                download={record.url}
              >
                Download
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={() => openModal("editPdf", record)}
              >
                Edit
              </Button>
            </Col>

            <Col>
              <Button danger onClick={() => openModal("deletePdf", record)}>
                Delete
              </Button>
            </Col>
          </Row>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div style={{ marginTop: 20 }}>
        <Row gutter={4}>
          <Col>
            <Button type="primary" onClick={() => openModal("createPdf")}>
              Create Pdf
            </Button>
          </Col>

          <Col>
            <Search
              value={searchText}
              placeholder="Search here.."
              onSearch={onSearch}
              style={{ width: 200 }}
              onChange={handleSearchChange}
            />
          </Col>
        </Row>
      </div>

      {formError ? (
        <div style={{ marginTop: 20 }}>
          {" "}
          <Alert message={formError} type="error" />{" "}
        </div>
      ) : (
        ""
      )}

      <div style={{ marginTop: 20 }}>
        <Table
          columns={columns}
          dataSource={
            searchText && searchedResults.length > 0
              ? searchedResults
              : Array.isArray(pdfs)
              ? pdfs
              : []
          }
        />
      </div>

      <Modal
        title={
          type === "createPdf"
            ? "Create Pdf"
            : type === "editPdf"
            ? "Edit Pdf"
            : type === "deletePdf"
            ? "Delete Pdf"
            : "Insert something here"
        }
        visible={isModalVisible}
        onCancel={closeModal}
        footer={[
          <Button key="back" onClick={closeModal}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            danger={type === "deletePdf"}
            loading={loading}
            onClick={handleOk}
          >
            {type === "createPdf"
              ? "Create"
              : type === "editPdf"
              ? "Update"
              : type === "deletePdf"
              ? "Delete"
              : "Done"}
          </Button>,
        ]}
      >
        {type === "createPdf" || type === "editPdf" ? (
          <CreateUpdateForm
            kind={kind}
            name={name}
            url={url}
            handleInputChange={handleInputChange}
            handleSelectChange={handleSelectChange}
            beforeUpload={beforeUpload}
            handleUpload={handleUpload}
            pdfError={pdfError}
            pdfUpload={pdfUpload}
            pdfUrl={pdfUrl}
            thumbnailURL={thumbnailURL}
            thumbnail={thumbnail}
            handleUploadImg={handleUploadImg}
          />
        ) : type === "deletePdf" ? (
          <DeleteForm badge={name} />
        ) : (
          <div>No form type selected</div>
        )}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  pdfs: state.pdfs.pdfs,
  pdfError: state.pdfs.pdfError,
  searchResult: state.tracks.searchResult,
  searchError: state.tracks.searchError,
});

export default connect(mapStateToProps, {
  createPdf,
  deletePdf,
  updatePdf,
  fetchPdfs,
  searchTrack,
})(Pdfs);
