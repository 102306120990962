import React from "react";
import ReactQuill from "react-quill";
import { imageHandler } from "../../store/actions/postsActions";
// import VideoResize from "quill-video-resize-module";

// Quill.register("modules/VideoResize", VideoResize);

function Editor({ value, onChange }) {
  return (
    <ReactQuill
      theme="snow"
      modules={{
        toolbar: {
          container: [
            [{ header: [1, 2, false] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            ["link", "image", "video"],
            ["clean"],
          ],
          handlers: {
            image: imageHandler,
          },
        },
      }}
      formats={[
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "video",
        "image",
      ]}
      value={value}
      onChange={onChange}
    />
  );
}

export default Editor;
