import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Input, Modal, Row, Table } from "antd";
import {
  DeleteOutlined,
  // EditOutlined,
} from "@ant-design/icons";

import { connect } from "react-redux";

import CreateUpdateForm from "./CreateUpdateForm";
import DeleteForm from "./DeleteForm";

import {
  fetchShortLinks,
  createShortLink,
  deleteShortLink,
  updateShortLink,
} from "../../store/actions/shortLinksActions";

import { getPosts } from "../../store/actions/postsActions";
import { searchTrack } from "../../store/actions/trackActions";

const { Search } = Input;

const ShortLinks = (props) => {
  const {
    shortLinks,
    fetchShortLinks,
    createShortLink,
    updateShortLink,
    deleteShortLink,
    shortLinkError,
    posts,
    getPosts,
    user,
    searchTrack,
    searchResult,
  } = props;

  const [formError] = useState("");
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("createShortLink");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [shortLinkId, setShortLinkId] = useState(null);
  const [name, setName] = useState("");
  const [url, setURL] = useState("");
  const [destination, setDestination] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchedResults, setSearchedResults] = useState([]);

  useEffect(() => {
    if (searchResult && searchText) {
      setSearchedResults(searchResult.dynamicLinks || []);
    }

    if (!searchText || searchText === "") {
      setSearchedResults([]);
    }
  }, [searchResult, searchText]);

  useEffect(() => {
    fetchShortLinks();
    getPosts();
  }, [fetchShortLinks, getPosts]);

  const openModal = (modalType, record) => {
    setIsModalVisible(true);
    setType(modalType);
    setName(record ? record.name : "");
    setDestination(record ? record.destination : "");
    setShortLinkId(record ? record.id : "");
    setURL(`https://post.paradigma.education/${url}`);
  };

  const clearFormData = () => {
    setName("");
    setURL("");
    setDestination("");
  };

  const closeModal = () => {
    setIsModalVisible(false);
    clearFormData();
  };

  const handleOk = async () => {
    setLoading(true);

    if (type === "createShortLink") {
      const finalPayload = {
        name,
        url,
        thumbnail,
        destination,
      };
      await createShortLink(finalPayload);
    }

    if (type === "updateShortLink") {
      const finalPayload = {
        name,
        url,
        destination,
      };
      await updateShortLink(finalPayload, shortLinkId);
    }

    if (type === "deleteShortLink") {
      await deleteShortLink(shortLinkId);
    }

    setLoading(false);
    clearFormData();
    closeModal();
    await fetchShortLinks();
  };

  const handleInputChange = (event, eventType) => {
    if (eventType === "destination") {
      setDestination(
        `https://app.paradigma.education/feed/post/view?id=${event}`
      );
      setThumbnail(
        posts.filter((el) => {
          return el.id === event;
        })[0].thumbnail
      );
      handleInputName(event);
    }
    if (eventType === "url") {
      setURL(event.target.value);
    }
  };

  const handleInputName = (event) => {
    const post = posts.find((post) => post.id === event);
    setName(post.title);
  };

  const onSearch = async (value) => {
    if (value) {
      await searchTrack(value);
    }
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "name",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.url.localeCompare(b.url),
    },
    {
      title: "Destination",
      dataIndex: "destination",
      key: "name",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.destination.localeCompare(b.destination),
    },
    {
      title: "Data de criação",
      dataIndex: "createdAt",
      key: "name",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (_, record) => (
        <div>
          <Row gutter={4}>
            {/* <Col>
              {user.isAuthor || user.clearanceLevel === "admin" ? (
                <Button
                  type="primary"
                  onClick={() => openModal("updateShortLink", record)}
                >
                  <EditOutlined />
                </Button>
              ) : null}
            </Col> */}

            <Col>
              {user.clearanceLevel === "admin" ? (
                <Button
                  danger
                  onClick={() => openModal("deleteShortLink", record)}
                >
                  <DeleteOutlined />
                </Button>
              ) : null}
            </Col>
          </Row>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div>
        <Row gutter={4}>
          <Col>
            {user.isAuthor || user.clearanceLevel === "admin" ? (
              <Button
                type="primary"
                onClick={() => openModal("createShortLink")}
              >
                Create Short Link
              </Button>
            ) : null}
          </Col>

          <Col>
            <Search
              value={searchText}
              onSearch={onSearch}
              onChange={handleSearchChange}
              placeholder="Search here..."
              style={{ width: 200 }}
            />
          </Col>
        </Row>
      </div>

      {formError ? (
        <div style={{ marginTop: 20 }}>
          {" "}
          <Alert message={formError} type="error" />{" "}
        </div>
      ) : (
        ""
      )}
      <div style={{ marginTop: 20 }}>
        <Table
          columns={columns}
          dataSource={
            searchText && searchedResults.length > 0
              ? searchedResults
              : Array.isArray(shortLinks)
              ? shortLinks
              : []
          }
        />
      </div>

      <Modal
        title={
          type === "createShortLink"
            ? "Create Short Link"
            : type === "updateShortLink"
            ? "Edit Short Link"
            : type === "deleteShortLink"
            ? "Delete Short Link"
            : "Insert something here"
        }
        visible={isModalVisible}
        onCancel={closeModal}
        footer={[
          <Button key="back" onClick={closeModal}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            danger={type === "deleteShortLink"}
            loading={loading}
            onClick={handleOk}
          >
            {type === "createShortLink"
              ? "Create"
              : type === "updateShortLink"
              ? "Update"
              : type === "deleteShortLink"
              ? "Delete"
              : "Done"}
          </Button>,
        ]}
      >
        {type === "createShortLink" || type === "updateShortLink" ? (
          <CreateUpdateForm
            name={name}
            url={url}
            posts={posts}
            handleInputChange={handleInputChange}
            shortLinkError={shortLinkError}
          />
        ) : type === "deleteShortLink" ? (
          <DeleteForm shortLink={name} />
        ) : (
          <div>No form type selected</div>
        )}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  shortLinks: state.shortLinks.shortLinks || [],
  shortLinkError: state.shortLinks.shortLinkError,
  posts: state.posts.posts,
  searchResult: state.tracks.searchResult,
  searchError: state.tracks.searchError,
});

export default connect(mapStateToProps, {
  fetchShortLinks,
  createShortLink,
  deleteShortLink,
  updateShortLink,
  getPosts,
  searchTrack,
})(ShortLinks);
