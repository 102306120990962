import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Input, Modal, Row, Table } from "antd";
import InviteForm from "./InviteForm";
import { connect } from "react-redux";
import {
  fetchAllOrders,
  fetchAllLeads,
  fetchAllUsers,
  inviteUser,
  updateUserPermission,
} from "../../store/actions/permissions";
import { CSVLink } from "react-csv";

const { Search } = Input;

const userHeaders = [
  {
    label: "Id",
    key: "id",
  },
  {
    label: "Name",
    key: "name",
  },
  {
    label: "Email",
    key: "email",
  },
  {
    label: "Plan",
    key: "pplan",
  },
  {
    label: "Video Creator",
    key: "isVideoCreator",
  },
  {
    label: "Author",
    key: "isAuthor",
  },
  {
    label: "Created At",
    key: "createdOn",
  },
];

const orderHeaders = [
  {
    label: "Email",
    key: "email",
  },
];

const leadsHeaders = [
  {
    label: "Email",
    key: "email",
  },
  {
    label: "Name",
    key: "name",
  },
  {
    label: "Phone",
    key: "phone",
  },
];

const UsersAndPermissions = ({
  users,
  inviteUser,
  fetchAllUsers,
  updateUserPermission,
  usersError,
  updatePermMessage,
  updatePermError,
  inviteUserError,
  inviteUserMessage,
  fetchAllOrders,
  fetchAllLeads,
  orders,
  leads,
}) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isAuthor, setAuthor] = useState("false");
  const [isVideoCreator, setVideoCreator] = useState("false");
  const [password, setPassword] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formError, setFormError] = useState("");
  const [searchedUsers, setSearchedUsers] = useState(null);
  const [freeUsers, setFreeUsers] = useState([]);

  // eslint-disable-next-line
  const emailRegex =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;

  const regexFinder = new RegExp(emailRegex);

  useEffect(() => {
    fetchAllUsers();
    fetchAllOrders();
    fetchAllLeads();
  }, [fetchAllUsers, fetchAllOrders, fetchAllLeads]);

  useEffect(() => {
    setFreeUsers(users.filter((el) => el.pplan === 0));
  }, [users]);

  const handleOk = async () => {
    setLoading(true);

    if (!email || !password || !name) {
      setFormError("name, email and password are required");
      return false;
    }

    if (!regexFinder.test(email)) {
      setFormError("enter a valid email");
      return false;
    }

    setLoading(true);
    setFormError("");

    const payload = {
      name,
      email,
      isVideoCreator: isVideoCreator === "true",
      isAuthor: isAuthor === "true",
      password,
    };
    await inviteUser(payload);
    setLoading(false);
    setFormError("");
    setName("");
    setPassword("");
    setEmail("");
    return true;
  };

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setFormError("");
    setName("");
    setPassword("");
    setEmail("");
    setIsModalVisible(false);
  };

  const pathPermission = async (payload, operation, type) => {
    await updateUserPermission(payload, operation, type);
    await fetchAllUsers();
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <div>{record.displayName ? record.displayName : record.name}</div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Author",
      dataIndex: "isAuthor",
      key: "isAuthor",
      render: (_, record) => (
        <div>
          <Button type="primary" danger={!record.isAuthor}>
            {record.isAuthor ? "True" : "False"}
          </Button>
        </div>
      ),
    },
    {
      title: "Video creator",
      dataIndex: "isVideoCreator",
      key: "isVideoCreator",
      render: (_, record) => (
        <div>
          <Button type="primary" danger={!record.isVideoCreator}>
            {record.isVideoCreator ? "True" : "False"}
          </Button>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (_, record) => (
        <div>
          <Row gutter={4}>
            <Col>
              <Button
                type="primary"
                danger={record.isAuthor}
                onClick={() =>
                  pathPermission(
                    record,
                    record.isAuthor ? "revokeAuthor" : "makeAuthor",
                    "isAuthor"
                  )
                }
              >
                {record.isAuthor ? "Revoke Author" : "Make Author"}
              </Button>
            </Col>

            <Col>
              <Button
                type="primary"
                danger={record.isVideoCreator}
                onClick={() =>
                  pathPermission(
                    record,
                    record.isVideoCreator
                      ? "revokeVideoCreator"
                      : "makeVideoCreator",
                    "isVideoCreator"
                  )
                }
              >
                {record.isVideoCreator
                  ? "Revoke video creator"
                  : "Make video creator"}
              </Button>
            </Col>
          </Row>
        </div>
      ),
    },
  ];

  const onSearch = (value) => {
    if (users.length > 0 && regexFinder.test(value)) {
      const filtered = users.filter((user) => user.email === value);
      setSearchedUsers(filtered);
    } else {
      setSearchedUsers(null);
    }
  };

  return (
    <div>
      <div>
        <Row gutter={4}>
          <Col>
            <Button type="primary" onClick={openModal}>
              Invite User
            </Button>
          </Col>

          <Col>
            <CSVLink
              data={freeUsers}
              headers={userHeaders}
              filename="users.csv"
            >
              <Button className="btn-green">Export Users</Button>
            </CSVLink>
          </Col>

          <Col>
            <CSVLink
              data={leads ? leads : []}
              headers={leadsHeaders}
              filename="leads.csv"
            >
              <Button className="btn-dull-green">Export Leads</Button>
            </CSVLink>
          </Col>
          <Col>
            <CSVLink data={orders} headers={orderHeaders} filename="orders.csv">
              <Button className="btn-dull-green">Export Orders</Button>
            </CSVLink>
          </Col>
        </Row>
      </div>

      {updatePermMessage || inviteUserMessage ? (
        <div style={{ marginTop: 20 }}>
          {updatePermMessage ? (
            <div>
              <Alert message={updatePermMessage} type="success" closable />
            </div>
          ) : null}

          {inviteUserMessage ? (
            <div style={{ marginTop: 5 }}>
              <Alert message={inviteUserMessage} type="success" closable />
            </div>
          ) : null}
        </div>
      ) : null}

      <div style={{ marginTop: 20 }}>
        <Search
          placeholder="Search user by email"
          allowClear
          enterButton="Search"
          size="large"
          onSearch={onSearch}
        />
      </div>

      {formError || inviteUserError || updatePermError || usersError ? (
        <div style={{ marginTop: 20 }}>
          {formError ? (
            <div>
              <Alert message={formError} type="error" />
            </div>
          ) : null}

          {inviteUserError ? (
            <div style={{ marginTop: 5 }}>
              <Alert message={inviteUserError} type="error" />
            </div>
          ) : null}

          {usersError ? (
            <div style={{ marginTop: 5 }}>
              <Alert message={usersError} type="error" />
            </div>
          ) : null}

          {updatePermError ? (
            <div style={{ marginTop: 5 }}>
              <Alert message={updatePermError} type="error" />
            </div>
          ) : null}

          {usersError ? (
            <div style={{ marginTop: 5 }}>
              <Alert message={usersError} type="error" />
            </div>
          ) : null}
        </div>
      ) : null}

      <div style={{ marginTop: 20 }}>
        <Table
          columns={columns}
          dataSource={searchedUsers ? searchedUsers : users}
        />
      </div>

      <Modal
        title="Invite user"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={closeModal}
        footer={[
          <Button key="back" onClick={closeModal}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            {loading ? "Inviting" : "Invite"}
          </Button>,
        ]}
      >
        <InviteForm
          password={password}
          email={email}
          name={name}
          isAuthor={isAuthor}
          isVideoCreator={isVideoCreator}
          setName={setName}
          setEmail={setEmail}
          setAuthor={setAuthor}
          setVideoCreator={setVideoCreator}
          setPassword={setPassword}
          formError={formError}
          setFormError={setFormError}
          inviteUserError={inviteUserError}
        />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  users: state.permissions.users,
  usersError: state.permissions.usersError,
  updatePermMessage: state.permissions.updatePermMessage,
  updatePermError: state.permissions.updatePermError,
  inviteUserMessage: state.permissions.inviteUserMessage,
  inviteUserError: state.permissions.inviteUserError,
  orders: state.permissions.orders,
  leads: state.permissions.leads,
});

export default connect(mapStateToProps, {
  fetchAllUsers,
  fetchAllOrders,
  fetchAllLeads,
  updateUserPermission,
  inviteUser,
})(UsersAndPermissions);
