import React from 'react';

const Web3Delete = () => {
    return (
        <div>
            <h4>Are you sure you want to delete this Collection?</h4>
        </div>
    )
}

export default Web3Delete;