import axios from "axios";
import {
  FETCH_ALL_USERS,
  FAILED_ALL_USER_FETCH,
  UPDATE_USER_PERMISSION,
  FAILED_USER_UPDATE_PERMISSION,
  INVITE_USER,
  FAILED_USER_INVITE,
  FETCH_ALL_ORDERS,
  FETCH_ALL_LEADS,
  FAILED_TO_FETCH_ALL_ORDERS,
} from "./types";

export const fetchAllUsers = () => async (dispatch) => {
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_BACKEND_APP_API}/users`
    );
    return dispatch({
      type: FETCH_ALL_USERS,
      payload: resp.data.users,
    });
  } catch (error) {
    return dispatch({
      type: FAILED_ALL_USER_FETCH,
      payload: "",
    });
  }
};

export const fetchAllOrders = () => async (dispatch) => {
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/orders/8-daysago`
    );
    return dispatch({
      type: FETCH_ALL_ORDERS,
      payload: resp.data.data,
    });
  } catch (error) {
    return dispatch({
      type: FAILED_TO_FETCH_ALL_ORDERS,
      payload: [],
    });
  }
};

export const fetchAllLeads = () => async (dispatch) => {
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/leads`
    );
    return dispatch({
      type: FETCH_ALL_LEADS,
      payload: resp.data.leads,
    });
  } catch (error) {
    return dispatch({
      type: FAILED_TO_FETCH_ALL_ORDERS,
      payload: [],
    });
  }
};

export const updateUserPermission =
  (payload, operation, type) => async (dispatch) => {
    try {
      const currentOperation =
        type === "isAuthor"
          ? {
              isAuthor: operation !== "revokeAuthor",
            }
          : {
              isVideoCreator: operation !== "revokeVideoCreator",
            };
      const nuPayload = {
        ...payload,
        ...currentOperation,
      };
      const resp = await axios.patch(
        `${process.env.REACT_APP_BACKEND_APP_API}/users/permission`,
        nuPayload
      );
      return dispatch({
        type: UPDATE_USER_PERMISSION,
        payload: resp.data.message,
      });
    } catch (error) {
      return dispatch({
        type: FAILED_USER_UPDATE_PERMISSION,
        payload: "",
      });
    }
  };

export const inviteUser = (payload) => async (dispatch) => {
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_BACKEND_APP_API}/invite`,
      {
        ...payload,
      }
    );
    return dispatch({
      type: INVITE_USER,
      payload: resp.data.message,
    });
  } catch (error) {
    return dispatch({
      type: FAILED_USER_INVITE,
      payload: error?.response?.data?.error,
    });
  }
};
