import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getCouponPurchasesByCode } from "../../store/actions/couponsActions";
import { Table, Spin, Button } from "antd";
import { CSVLink } from "react-csv";
import { SmileOutlined } from "@ant-design/icons";
import {withRouter} from "react-router-dom";

const csvHeaders = [
  { label: "Data Compra", key: "purchaseDate" },
  { label: "Email", key: "email" },
  { label: "Meio Pagamento", key: "payment_method" },
  { label: "Valor Pago", key: "amount" },
];

const CouponDetail = ({ coupons_filter, match, getCouponPurchasesByCode }) => {
  const { id } = match.params;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      await getCouponPurchasesByCode(id);
      setLoading(false);
    };
    getData();
  }, [getCouponPurchasesByCode, id]);
  
  const columns = [
    {
      title: "DataCompra",
      dataIndex: "purchaseDate",
      key: "purchaseDate",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "MeioPagamento",
      dataIndex: "payment_method",
      key: "payment_method",
    },
    {
      title: "ValorPago",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => decimalFormat(amount),
    },
  ];

  const csvReport = {
    data: coupons_filter,
    headers: csvHeaders,
    filename: "Compras_cupons.csv",
  };

  const decimalFormat = (value) => parseFloat(value / 100).toFixed(2);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingBottom: "1rem",
        }}
      >
        <Button type="primary">
          <CSVLink {...csvReport}><SmileOutlined type="file" /> Exportar</CSVLink>
        </Button>

        <Button type="ghost" style={{marginLeft: 20}} href="/cuopons">
          Back
        </Button>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          CUPOM: <span style={{ fontWeight: "bold" }}>{id}</span>
        </div>
        <div>
          COMPRAS:
          <span style={{ fontWeight: "bold" }}>{coupons_filter.length}</span>
        </div>
        <div>
          TOTAL:
          <span style={{ fontWeight: "bold" }}>
            {decimalFormat(
              coupons_filter
                .map((item) => item.amount)
                .reduce((prev, curr) => prev + curr, 0)
            )}
          </span>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingBottom: "1rem",
        }}
      ></div>
      <Spin spinning={loading}>
        <Table rowKey="id" columns={columns} dataSource={coupons_filter} />
      </Spin>
    </div>
  );
}

const mapStateToProps = (state) => ({
  coupons_filter: state.coupons.coupons_filter,
});
export default connect(mapStateToProps, { getCouponPurchasesByCode })(
  withRouter(CouponDetail)
);
