import React from 'react'

const DeleteBlocker = (props) => {
    const { type } = props;

    return (
        <div>
            <p>
                Are you sure you want to delete {type === "deleteProfile" ? "curated profile." : "coin"}
            </p>
        </div>
    )
}

export default DeleteBlocker
