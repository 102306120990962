import {
  FETCH_TAGS,
  TAGS_ERROR,
} from "./types";
import axios from "axios";

export const fetchTags = () => async (dispatch) => {
  try {
    const data = await axios.get(`${process.env.REACT_APP_GOLANG_API}/api/v2/tags`);
    return dispatch({
      type: FETCH_TAGS,
      payload: data.data && data.data.data ? data.data.data : data.data,
    });
  } catch (error) {
    return dispatch({
      type: TAGS_ERROR,
      payload: error.response
        ? error.response.data.error
        : error.message
        ? error.message
        : error.error,
    });
  }
};