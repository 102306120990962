import React from "react";
import { connect } from "react-redux";
import { logOut } from "../../store/actions/authActions";
import { Layout, Button } from "antd";
import { MenuFoldOutlined } from "@ant-design/icons";

const { Header: AntHeader } = Layout;

const Header = ({ toggleMenu, showMenu, logOut }) => {
  return (
    <AntHeader className="header">
      <MenuFoldOutlined
        className="trigger"
        type={showMenu ? "menu-unfold" : "menu-fold"}
        onClick={toggleMenu}
      />
      <Button onClick={logOut}>Log Out</Button>
    </AntHeader>
  );
};

export default connect(null, {
  logOut,
})(Header);
