import React from "react";

const Home = ({ user }) => {
    return (
        <div>
            <p>Currently logged in user: {user?.name}</p>
            <p>Video creation and edit permission: {user.isVideoCreator ? "true" : "false"}</p>
            <p>Post creation and edit permission: {user.isAuthor ? "true" : "false"}</p>
        </div>
    )
} 

export default Home;
