import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table, Spin, Button, Modal } from "antd";

import { connect } from "react-redux";

import {
  getGraficos,
  deleteGraficos
} from "../../store/actions/graficosActions";
import format from "date-fns/format";
import fromUnixTime from "date-fns/fromUnixTime";
const { confirm } = Modal;

const Ponta = {
  compra: { text: "Compra", color: "#52D4A8" },
  venda: { text: "Venda", color: "#FF638C" },
  neutra: { text: "Neutra", color: "#B3B3B3" }
};
const Modelo = {
  tendencia: { text: "Tendência", color: "#52D4A8" },
  candle: { text: "Candle", color: "#A2B3FF" },
  rompimento: { text: "Rompimento", color: "#A2B3FF" },
  volatilidade: { text: "Volatilidade", color: "#FF638C" }
};
function Graficos({ getGraficos, graficos, deleteGraficos }) {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      await getGraficos();
      setLoading(false);
    };
    getData();
  }, [getGraficos]);

  const columns = [
    {
      title: "",
      dataIndex: "coinImg",
      key: "coinImg",
      render: url => <img width="33" alt="coin" src={url} />
    },
    {
      title: "Ativo",
      dataIndex: "ativoFull",
      key: "ativoFull",
      render: (ativoFull, record) => (
        <div>
          <h3 style={{ color: "#7C94FF" }}>{ativoFull}</h3>
          <span>{record.ativoAbr}</span>
        </div>
      )
    },
    {
      title: "Ponta",
      dataIndex: "ponta",
      key: "ponta",
      render: text => (
        <h3
          style={{
            color: Ponta[text].color
          }}
        >
          {Ponta[text].text}
        </h3>
      )
    },
    {
      title: "Modelo",
      dataIndex: "modelo",
      key: "modelo",
      render: (modelo, record) => (
        <div
          style={{
            display: "flex"
          }}
        >
          <div
            className="badge"
            style={{
              background: Modelo[modelo].color
            }}
          >
            {Modelo[modelo].text}
          </div>
          <h3
            style={{
              color: "#ADADAD"
            }}
          >
            {record.modeloURL ? (
              <a
                href={record.modeloURL}
                target="_blanc"
                style={{
                  color: "#ADADAD"
                }}
              >
                {record.modeloText}
              </a>
            ) : (
              record.modeloText
            )}
          </h3>
        </div>
      )
    },
    {
      title: "TimeFrame",
      dataIndex: "timeFrame",
      key: "timeFrame",
      render: (timeframe, record) => (
        <div>
          <h3
            style={{
              color: "#ADADAD"
            }}
          >
            {timeframe}
          </h3>
          <span
            style={{
              color: "#ADADAD"
            }}
          >
            {format(
              fromUnixTime(record.timeFrameDate.seconds),
              "yyyy-MM-dd HH:mm"
            )}
          </span>
        </div>
      )
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <span>
          <Button
            type="danger"
            onClick={() =>
              confirm({
                title: "Do you Want to delete this Graficos?",
                okText: "Yes",
                okType: "danger",
                cancelText: "No",
                onOk() {
                  deleteGraficos(record.id);
                }
              })
            }
          >
            Delete
          </Button>
          <Button type="info">
            <Link to={`/graficos/${record.id}/edit`}>Edit</Link>
          </Button>
        </span>
      )
    }
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingBottom: "1rem"
        }}
      >
        <Button type="primary">
          <Link to="/graficos/new">Create Graficos</Link>
        </Button>
      </div>
      <Spin spinning={loading}>
        <Table dataSource={graficos} columns={columns} />
      </Spin>
    </div>
  );
}
const mapStateToProps = state => ({
  graficos: state.graficos.graficos
});
export default connect(mapStateToProps, { getGraficos, deleteGraficos })(
  Graficos
);
