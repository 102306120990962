import React, { useState, useEffect } from "react";
import {Button, Col, Modal, Row, Table, message} from "antd";
import {StepBackwardOutlined, VideoCameraAddOutlined} from "@ant-design/icons";
import VideoForm from "../videos/components/VideoForm";
import {uploadOtherFilesToFirebase, uploadVideoToFirebase} from "../../utils/uploadFileToFirebase";
import {createTrack, fetchModule, updateTrack} from "../../store/actions/trackActions";
import {connect} from "react-redux";
import {createVideo, deleteVideo, editVideo} from "../../store/actions/videoActions";
import DeleteVideo from "./DeleteVideo";

const Chapter = ({
                     user,
                     createTrack,
                     singleModule,
                     fetchModule,
                     editVideo,
                     createVideo,
                     deleteVideo
}) => {
    const moduleId = window.location.pathname.split("/")[2];
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState("videoUpload");
    const [modalType, setModalType] = useState(false);
    const [formError, setFormError] = useState("");
    const [creationInProgress, setCreationInProgress] = useState(false);
    const [chapterPayload, setChapterPayload] = useState({
        name: "",
        videoURL: "",
        tags: [],
        levels: "Iniciante",
        description: "",
        thumbnailURL: null,
        videoUpload: null,
        thumbnailUpload: null,
        videoProgress: null,
        isVideoInProgress: false,
        pdfUpload: null,
        pdfURL: null,
        videoUploadError: null,
        pdfUploadError: null,
        thumbnailUploadError: null,
        pdfUploadProgress: 0,
        isFeatured: "false",
        videoId: null
    })

    useEffect(() => {
        fetchModule(moduleId)
    }, [fetchModule, moduleId])

    // Pause if errors occur in video / thumbnail upload
    useEffect(() => {
        if (creationInProgress && chapterPayload.videoUploadError) {
            setLoading(false);
            setCreationInProgress(false);
        }

        if (creationInProgress && chapterPayload.thumbnailUploadError) {
            setLoading(false);
            setCreationInProgress(false);
        }

        if (creationInProgress && chapterPayload.thumbnailURL && chapterPayload.videoURL){
            setLoading(false);
            setModalType(false);
            setCreationInProgress(false);

            const finalPayload = {
                name: chapterPayload.name,
                videoURL: chapterPayload.videoURL,
                videoId: chapterPayload.videoURL,
                difficulty: chapterPayload.levels,
                pdfURL: chapterPayload.pdfURL,
                thumbnail: chapterPayload.thumbnailURL,
                description: chapterPayload.description,
                tags: chapterPayload.tags,
                time: 10,
                isLocalUpload: true,
                id: chapterPayload.id
            }

            if (type === "videoUpload") {
                createVideo(finalPayload, true)
            }

            if (type === "editVideo") {
                editVideo(finalPayload, true)
            }

            setChapterPayload({
                name: "",
                videoURL: "",
                tags: [],
                levels: "Iniciante",
                thumbnailURL: null,
                videoUpload: null,
                thumbnailUpload: null,
                videoProgress: 0,
                isVideoInProgress: false,
                pdfUpload: null,
                pdfURL: null,
                videoUploadError: null,
                pdfUploadError: null,
                thumbnailUploadError: null,
                pdfUploadProgress: 0,
                isFeatured: "false"
            })
            setFormError("");
        }
    }, [
        chapterPayload.thumbnailURL,
        chapterPayload.thumbnailUploadError,
        chapterPayload.videoUploadError,
        chapterPayload.videoURL,
        creationInProgress,
        chapterPayload.pdfURL,
        chapterPayload.pdfUploadError,
        chapterPayload,
        moduleId,
        createTrack,
        type,
        createVideo,
        editVideo
    ])

    const openModal = (modalType, record) => {
        setModalType(true);
        setType(modalType);

        if (record) {
            setChapterPayload({
                ...chapterPayload,
                name: record.name,
                videoURL:record.hlsUrl,
                tags: record.tags,
                levels: record.difficulty,
                thumbnailURL: record.thumbnail,
                pdfURL: record.pdfURL,
                videoUploadError: null,
                description: record.description,
                isFeatured: "false",
                id: record.id
            });
        }
    }

    const closeModal = () => {
        setModalType(false);
        setChapterPayload({
            name: "",
            videoURL: null,
            tags: [],
            levels: "",
            thumbnailURL: null,
            videoUpload: null,
            thumbnailUpload: null,
            videoProgress: null,
            isVideoInProgress: false,
            pdfUpload: null,
            pdfURL: "",
            videoUploadError: null,
            pdfUploadError: null,
            thumbnailUploadError: null,
            description: ""
        })
    }

    const beforeUpload = (file, fileType) => {
        if (fileType === "thumbnail") {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
            if (!isJpgOrPng) {
                message.error('You can only upload a JPG/PNG file!');
                setChapterPayload({
                    ...chapterPayload,
                    thumbnailUploadError:"You can only upload a JPG/PNG file!"
                })
                return false
            }
            const isLt2M = file.size <= 2000000;
            if (!isLt2M) {
                message.error('Image must be smaller than 2MB!');
                setChapterPayload({
                    ...chapterPayload,
                    thumbnailUploadError: 'Image must be smaller than 2MB!'
                })
                return false
            }
            setChapterPayload({
                ...chapterPayload,
                thumbnailUploadError: null
            })
            return isJpgOrPng && isLt2M;
        }

        // video
        if (fileType === "video") {
            const isMP4 = file.type === 'video/mp4';
            if (!isMP4) {
                message.error('You can only upload mp4 video files');
                setChapterPayload({
                    ...chapterPayload,
                    videoUpload: null,
                    videoUploadError:"You can only upload mp4 video files"
                })
                return false
            }
            const isLt1GB = file.size <= 15000000000
            if (!isLt1GB) {
                message.error('Video must be smaller than 1.5 GB!');
                setChapterPayload({
                    ...chapterPayload,
                    videoUpload: null,
                    videoUploadError: 'Video must be smaller than 1.5 GB!'
                })
                return false
            }

            setChapterPayload({
                ...chapterPayload,
                videoUploadError: null
            })
            return isMP4 && isLt1GB;
        }

        //pdf
        // if (fileType === "pdf") {
        //     const isPDF = file.type === 'application/pdf'
        //     if (!isPDF){
        //         message.error('You can only upload pdf files');
        //         setChapterPayload({
        //             ...chapterPayload,
        //             pdfUpload: null,
        //             pdfUploadError:"You can only upload pdf files"
        //         })
        //         return false
        //     }
        //     const isLt5MB = file.size <= 20000000
        //     if (!isLt5MB) {
        //         message.error('PDF file must be smaller than 20 MB!');
        //         setChapterPayload({
        //             ...chapterPayload,
        //             pdfUpload: null,
        //             pdfUploadError: 'PDF file must be smaller than 20 MB!'
        //         })
        //         return false
        //     }
        //     setChapterPayload({
        //         ...chapterPayload,
        //         pdfUploadError: null
        //     })
        //     return isPDF && isLt5MB;
        // }

        return false
    }

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const handleSelectChanger = (value, stateKey) => {
        setChapterPayload({ ...chapterPayload, [stateKey]: value })
    }

    const handleTagChanger = (value) => {
        setChapterPayload({...chapterPayload, tags: value});
    }

    const handleInputChange = (event, eventType) => {
        setChapterPayload({ ...chapterPayload, [eventType]: event.target.value })
    }

    const handleUpload = (upload, uploadType) => {
        const progress = upload.event?.percent;
        if (uploadType === "video"){
            setChapterPayload({
                ...chapterPayload,
                videoUpload: upload,
                videoProgress: Math.trunc(progress)
            });
        } else if (uploadType === "pdf") {
            setChapterPayload({
                ...chapterPayload,
                pdfUpload: upload,
                pdfUploadProgress: Math.trunc(progress)
            });
        } else {
            getBase64(upload.file.originFileObj, (imageUrl) => {
                setChapterPayload({
                    ...chapterPayload,
                    thumbnailUpload: upload,
                    thumbnailURL: imageUrl
                })
            });
        }
    }

    const handleOk = async () => {
        setLoading(true);
        setCreationInProgress(true);

        const {
            name,
            videoUpload,
            thumbnailUpload,
            pdfUpload,
            description,
            videoURL,
            thumbnailURL,
            pdfURL
        } = chapterPayload;

        if (type === "videoUpload") {
            let pdfLink = "";

            if (!name || !videoUpload || !thumbnailUpload || !description) {
                setFormError("Name, description, video and thumbnail are required");
                setLoading(false);
                setCreationInProgress(false);
                return true
            }

            if (pdfUpload) {
                setChapterPayload({
                    ...chapterPayload,
                    pdfUploadProgress: 50
                })

                pdfLink = await uploadOtherFilesToFirebase(
                    pdfUpload.file,
                    'pdf'
                )
            }

            // Upload thumbnail
            const thumbUrl = await uploadOtherFilesToFirebase(
                thumbnailUpload.file,
                'thumbnail'
            )

            setChapterPayload({
                ...chapterPayload,
                thumbnailURL: thumbUrl,
                pdfURL: pdfLink,
                pdfUploadProgress: 100
            })

            const newFormulatedLoad = {
                ...chapterPayload,
                thumbnailURL: thumbUrl,
                pdfURL: pdfLink,
                pdfUploadProgress: 100
            }

            // Upload video
            await uploadVideoToFirebase({
                chapterPayload: newFormulatedLoad,
                setChapterPayload
            });
        }

        if (type === "editVideo") {
            let pdfLink = "";
            let thumbLink = "";

            if (!name || !description || !thumbnailURL) {
                setFormError("Name, thumbnail and description are required");
                setLoading(false);
                setCreationInProgress(false);
                return true
            }

            if (!videoUpload && !videoURL) {
                setFormError("Please upload a video");
                setLoading(false);
                setCreationInProgress(false);
                return true
            }

            if (pdfUpload) {
                setChapterPayload({
                    ...chapterPayload,
                    pdfUploadProgress: 50
                })

                pdfLink = await uploadOtherFilesToFirebase(
                    pdfUpload.file,
                    'pdf'
                )
                setChapterPayload({
                    ...chapterPayload,
                    pdfURL: pdfLink,
                    pdfUploadProgress: 100
                })
            }

            if (thumbnailURL && thumbnailUpload) {
                thumbLink = await uploadOtherFilesToFirebase(
                    thumbnailUpload.file,
                    'thumbnail'
                )
                setChapterPayload({
                    ...chapterPayload,
                    thumbnailURL: thumbLink
                })
            }

            const newFormulatedLoad = {
                ...chapterPayload,
                thumbnailURL: thumbnailURL && thumbnailUpload ? thumbLink : thumbnailURL,
                pdfURL: pdfUpload ? pdfLink : pdfURL,
                pdfUploadProgress: 100
            }

            if (videoURL && videoUpload) {
                await uploadVideoToFirebase({
                    chapterPayload: newFormulatedLoad,
                    setChapterPayload
                });
            }
        }

        if (type === "deleteVideo") {
            await deleteVideo(chapterPayload);
            setLoading(false);
            setCreationInProgress(false);
        }
    }

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Difficulty',
            dataIndex: 'difficulty',
            key: 'difficulty'
        },
        {
            title: 'Tags',
            dataIndex: 'tags',
            key: 'tags',
            render: (_, record) => <div>
                {record.tags && record.tags.length > 0 ? record.tags.map((tag) => {
                    return (
                        <Button style={{ marginRight: 5 }}>
                            {tag}
                        </Button>
                    )
                }) : null}
            </div>
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            render: (_, record) => <div>
                <Row gutter={4}>
                    {record.createdBy === user.email || user.clearanceLevel === "admin" ? <Col>
                        <Button type="primary" onClick={() => openModal('editVideo', record)}>Edit</Button>
                    </Col> : null}

                    {user.clearanceLevel === "admin" ? <Col>
                        <Button danger onClick={() => openModal('deleteVideo', record)}>Delete</Button>
                    </Col> : null}
                </Row>
            </div>
        },
    ]

    return (
        <div>
            <div>
                <Button
                    icon={<StepBackwardOutlined />}
                    size="middle"
                    href={`/modules`}
                >
                    Back to modules
                </Button>
            </div>

            <div style={{ marginTop: 20 }}>
                <h1 style={{marginBottom: 0}}>{singleModule.name}</h1>
                <small>{singleModule.description}</small>
            </div>

            <div style={{ marginTop: 20 }}>
                <Row gutter={4}>
                    <div>
                        <Button
                            icon={<VideoCameraAddOutlined />}
                            type="primary"
                            size="middle"
                            onClick={() => openModal("videoUpload")}
                        >
                            Create video
                        </Button>
                    </div>
                </Row>
            </div>

            <div style={{marginTop: 20}}>
                <Table
                    columns={columns}
                    dataSource={singleModule.videos && Array.isArray(singleModule.videos) ? singleModule.videos : []}
                />
            </div>

            <Modal
                title={type === "videoUpload" ? "Upload Video" : type === "editVideo" ? "Edit Video" :
                    type === "deleteVideo" ? "Delete Video" : type === "pdfUpload" ? "Upload PDF" :
                        "Insert something here"}
                visible={modalType}
                onCancel={closeModal}
                footer={[
                    <Button key="back" onClick={closeModal}>
                        Cancel
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        danger={type === "deleteTrack"}
                        loading={loading}
                        onClick={handleOk}
                    >
                        {type === "videoUpload" ? "Upload" : type === "editVideo" ? "Update" : type === "deleteVideo"
                            ? "Delete" : type === "pdfUpload" ? "Upload PDF" : "Done"}
                    </Button>
                ]}
            >
                {
                    type === "videoUpload" || type === "editVideo" ? <VideoForm
                        beforeUpload={beforeUpload}
                        formError={formError}
                        handleUpload={handleUpload}
                        videoPayload={chapterPayload}
                        handleTagChanger={handleTagChanger}
                        handleInputChange={handleInputChange}
                        handleSelectChanger={handleSelectChanger}
                        openModal={openModal}
                    /> : type === "deleteVideo" ? <DeleteVideo videoPayload={chapterPayload}  /> : null
                }
            </Modal>
        </div>
    )
}

const mapStateToProps = state => ({
    singleModule: state.tracks.singleModule,
    moduleError: state.tracks.moduleError
});

export default connect(mapStateToProps, {
    createTrack,
    fetchModule,
    updateTrack,
    createVideo,
    editVideo,
    deleteVideo
})(Chapter);