import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  getCouponsPurchases,
  getDates,
} from "../../store/actions/couponsActions";
import { Link } from "react-router-dom";
import { Table, Spin, Button, DatePicker } from "antd";
// import "chartkick/chart.js";
// import Chartkick, { LineChart } from "react-chartkick";

const { RangePicker } = DatePicker;

const columns = [
  {
    title: "Código",
    dataIndex: "coupon",
    key: "coupon",
    render: (coupon, rest) => {
      if (typeof coupon === "string") {
        return (
          <Button type="info">
            <Link to={`/cuopons/${rest.docId}`}>{coupon}</Link>
          </Button>
        );
      }
      if (coupon.name) {
        return (
          <Button type="info">
            <Link to={`/cuopons/${rest.docId}`}>{coupon.name}</Link>
          </Button>
        );
      }

      return (
        <Button type="info">
          <Link to={`/cuopons/${rest.docId}`}>{coupon.code}</Link>
        </Button>
      );
    },
  },
  {
    title: "Uselimit",
    dataIndex: "useLimit",
    key: "useLimit",
  },
  {
    title: "Status",
    dataIndex: "isActive",
    key: "isActive",
    render: (isActive) => (isActive ? "Válido" : "Inválido"),
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "DataCompra",
    dataIndex: "purchaseDate",
    key: "purchaseDate",
  },
  {
    title: "MeioPagamento",
    dataIndex: "payment_method",
    key: "payment_method",
  },
  {
    title: "Plano",
    dataIndex: "subscriptionPlan",
    key: "subscriptionPlan1",
    render: (subscriptionPlan) => subscriptionPlan.name,
  },
  {
    title: "ValorPago",
    dataIndex: "subscriptionPlan",
    key: "subscriptionPlan2",
    render: (subscriptionPlan, record) => {
      console.log("record", record);
      console.log("subscriptionPlan", subscriptionPlan);
      return parseFloat(subscriptionPlan.amount / 100).toFixed(2);
    },
  },
];

// Chartkick.options = {
//   scales: {
//     x: {
//       type: "time",
//       time: {
//         displayFormats: {
//           quarter: "DDD MMM YYYY",
//         },
//       },
//     },
//   },
// };

const CouponList = ({ coupons, getCouponsPurchases }) => {
  const [loading, setLoading] = useState(true);
  const [chartValues, setChartValues] = useState({});
  // const [newChartValues, setNewChartValues] = useState({});
  const [newCouponValues, setNewCouponValues] = useState([]);
  const [isDateChanged, setIsDateChanged] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      await getCouponsPurchases();
      setLoading(false);
    };
    getData();
  }, [getCouponsPurchases]);

  useEffect(() => {
    if (coupons.length > 0) {
      const chartValues = coupons
        .map((i) => i.purchaseDate)
        .reduce(function (obj, b) {
          obj[b] = ++obj[b] || 1;
          return obj;
        }, {});

      const mostRecentDate = new Date(
        Math.max(...Object.keys(chartValues).map((e) => new Date(e)))
      );
      const allDatesObject = getDates(
        new Date("Oct 28 2020"),
        new Date(mostRecentDate)
      );

      const finalChartValues = {
        ...allDatesObject,
        ...chartValues,
      };

      setChartValues(finalChartValues);
    }
  }, [coupons]);

  const onChange = (dates, dateStrings) => {
    const startDate = dateStrings[0];
    const endDate = dateStrings[1];

    const dateRanges = getDates(new Date(startDate), new Date(endDate));
    let newChartValues = {};

    // chartGraph
    for (const range of Object.keys(dateRanges)) {
      if (chartValues[range]) {
        newChartValues[range] = chartValues[range];
      }
    }

    // couponList
    const filteredCoupons = coupons.filter(
      (coupon) =>
        new Date(coupon.purchaseDate).getTime() >= new Date(startDate) &&
        new Date(coupon.purchaseDate).getTime() <= new Date(endDate)
    );

    setIsDateChanged(true);
    // setNewChartValues(newChartValues);
    setNewCouponValues(filteredCoupons);
  };

  const resetDate = () => {
    setIsDateChanged(false);
  };

  console.log("chartValues", chartValues);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "20px",
        }}
      >
        <div style={{ marginRight: 20 }}>
          <RangePicker onChange={onChange} size="large" />
        </div>

        <div>
          <Button type="primary" size="large" onClick={resetDate}>
            Reset
          </Button>
        </div>
      </div>
      {/* <LineChart data={isDateChanged ? newChartValues : chartValues} /> */}
      <Spin spinning={loading}>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={isDateChanged ? newCouponValues : coupons}
        />
      </Spin>
    </div>
  );
};

const mapStateToProps = (state) => ({
  coupons: state.coupons.coupons,
});
export default connect(mapStateToProps, { getCouponsPurchases })(CouponList);
