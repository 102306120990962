import React from "react";

const DeleteVideo = (props) => {
  const {
    videoPayload: { name },
  } = props;
  return (
    <div>
      <h4>
        Are you sure you want to delete this video{" "}
        <span style={{ color: "red" }}>{name}</span>
      </h4>
    </div>
  );
};

export default DeleteVideo;

