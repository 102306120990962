import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import {
  getGRAFICOS,
  updateGraficos,
} from "../../store/actions/graficosActions";
import { uploadToFirebase } from "../../store/actions/postsActions";
import { Form, Input, Button, Row, Col, Select, Spin, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";

// title summary author(s) Date Time Status Category Tags Acessos body
const { Option } = Select;

function NewGraficos({
  form,
  match,
  getGRAFICOS,
  updateGraficos,
  graficos,
}) {
  const [loading, setLoading] = useState(true);
  const [coinImg, setCoinImg] = useState(false);
  const [fields, setFields] = useState([
    {
      name: "ativoFull",
      value: "",
    },
    {
      name: "ativoAbr",
      value: "",
    },
    {
      name: "ponta",
      value: "",
    },
    {
      name: "modelo",
      value: "",
    },
    {
      name: "modeloText",
      value: "",
    },
    {
      name: "modeloURL",
      value: "",
    },
    {
      name: "timeFrame",
      value: "",
    },
  ]);

  const { id } = match.params;

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      await getGRAFICOS(id);
      setLoading(false);
    };
    getData();
  }, [id, getGRAFICOS]);

  useEffect(() => {
    if (graficos) {
      const newArray = Object.entries(graficos).map((entry) => {
        return {
          name: entry[0],
          value: entry[1],
        };
      });
      setFields(newArray);
    }
  }, [graficos]);

  const handleSubmit = async (values) => {
    setLoading(true);

    if (coinImg) {
      await updateGraficos(id, {
        ...values,
        coinImg,
        timeFrameDate: new Date(),
      });
    } else {
      await updateGraficos(id, { ...values, timeFrameDate: new Date() });
    }

    setLoading(false);
  };

  const uploadCoinImage = async ({ file, onError, onSuccess }) => {
    try {
      setLoading(true);
      const url = await uploadToFirebase(file);
      setLoading(false);
      setCoinImg(url);
      onSuccess();
    } catch (error) {
      console.log(error);
      onError();
    }
  };

  const validateFields = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
    setLoading(false);
  };

  const handleChange = (fields) => {
    setFields(fields);
  };

  if (!graficos) return null;

  return (
    <Form
      name="global_state"
      onFinish={handleSubmit}
      fields={fields}
      onFinishFailed={validateFields}
      onFieldsChange={(_, allFields) => {
        handleChange(allFields);
      }}
    >
      <Spin spinning={loading}>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              name="ativoFull"
              label="Ativo Full"
              rules={[{ required: true, message: "this field is required" }]}
            >
              <Input placeholder="Bitcoin" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="ativoAbr"
              label="Ativo Abr"
              rules={[{ required: true, message: "this field is required" }]}
            >
              <Input defaultValue={graficos.ativoAbr} placeholder="BTC" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="ponta"
              label="Ponta"
              rules={[{ required: true, message: "this field is required" }]}
            >
              <Select style={{ width: "100%" }} placeholder="Please select">
                <Option value="compra">Compra</Option>
                <Option value="venda">Venda</Option>
                <Option value="neutra">Neutra</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="modelo"
              label="Estratégia"
              rules={[{ required: true, message: "this field is required" }]}
            >
              <Select style={{ width: "100%" }} placeholder="Please select">
                <Option value="tendencia">Tendência</Option>
                <Option value="candle">Candle</Option>
                <Option value="rompimento">Rompimento</Option>
                <Option value="volatilidade">Volatilidade</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="modeloText"
              label="Estratégia text"
              rules={[{ required: true, message: "this field is required" }]}
            >
              <Input placeholder="Estratégia text" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="modeloURL" label="Estratégia URL">
              <Input placeholder="Estratégia URL" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="timeFrame"
              label="TimeFrame"
              rules={[{ required: true, message: "this field is required" }]}
            >
              <Input placeholder="TimeFrame" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Coin Image">
              <Upload
                customRequest={uploadCoinImage}
                listType="picture"
                className="upload-list-inline"
                fileList={[
                  {
                    uid: "-4",
                    name: "CoinImage",
                    status: "done",
                    url: graficos.coinImg,
                  },
                ]}
              >
                <Button>
                  <UploadOutlined /> Click to Upload
                </Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              disabled={loading}
              style={{
                display: "block",
                marginLeft: "auto",
                width: "100px",
              }}
            >
              Update
            </Button>
          </Col>
        </Row>
      </Spin>
    </Form>
  );
}

const mapStateToProps = (state) => ({
  graficos: state.graficos.currentGRAFICOS,
});
export default connect(mapStateToProps, { getGRAFICOS, updateGraficos })(
  NewGraficos
);
