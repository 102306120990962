import {
  FETCH_SHORT_LINKS,
  SHORT_LINK_ERROR,
  CREATE_SHORT_LINK,
  UPDATE_SHORT_LINK,
  DELETE_SHORT_LINK,
  // FETCH_SHORT_LINK,
} from "./types";
import axios from "axios";

export const createShortLink = (payload) => async (dispatch) => {
  try {
    const data = await axios.post(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/short-links`,
      payload
    );
    dispatch({
      type: CREATE_SHORT_LINK,
      payload: data.data,
    });
    return setShortLinkError("");
  } catch (error) {
    return dispatch({
      type: SHORT_LINK_ERROR,
      payload: error.response ? error.response.data.error : error.message,
    });
  }
};

export const setShortLinkError = (message) => (dispatch) => {
  return dispatch({
    type: SHORT_LINK_ERROR,
    payload: message,
  });
};

export const updateShortLink = (payload, shortLinkId) => async (dispatch) => {
  try {
    const data = await axios.patch(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/short-links/${shortLinkId}`,
      payload
    );
    
    return dispatch({
      type: UPDATE_SHORT_LINK,
      payload: data,
    });
  } catch (error) {
    return dispatch({
      type: SHORT_LINK_ERROR,
      payload: error.response ? error.response.data.error : error.message,
    });
  }
};

export const fetchShortLinks = () => async (dispatch) => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/short-links`
    );

    const videos = data.data.data
      ? data.data.data
      : data.data.data
      ? data.data.data
      : [];

    return dispatch({
      type: FETCH_SHORT_LINKS,
      payload: videos,
    });
  } catch (error) {
    return dispatch({
      type: SHORT_LINK_ERROR,
      payload: error.response ? error.response.data.error : error.message,
    });
  }
};

export const deleteShortLink = (shortLinkId) => async (dispatch) => {
  try {
    const data = await axios.delete(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/short-links/${shortLinkId}`
    );

    return dispatch({
      type: DELETE_SHORT_LINK,
      payload: data.data,
    });
  } catch (error) {
    return dispatch({
      type: SHORT_LINK_ERROR,
      payload: error.response ? error.response.data.error : error.message,
    });
  }
};
