import React from "react";
import { Input, Select } from "antd";

const Option = Select.Option;

const ViewForm = ({ review, scores, createdAt }) => {
  return (
    <div>
      <div>
        <label>Review</label>
        <Input value={review} defaultValue={review} disabled />
      </div>
      <div style={{ marginTop: 15 }}>
        <label>Created At</label>
        <Input value={createdAt} defaultValue={createdAt} disabled />
      </div>
      <div style={{ marginTop: 15 }}>
        <label>Scores</label>
        <Select
          allowClear
          mode="multiple"
          value={scores}
          defaultValue={scores}
          style={{ width: "100%" }}
          placeholder=""
          disabled
        >
          {scores && scores.length > 0
            ? scores.map((score, idx) => {
                return (
                  <Option value={score} key={idx}>
                    {score}
                  </Option>
                );
              })
            : null}
        </Select>
      </div>
    </div>
  );
};

export default ViewForm;
