import {
  FETCH_PDFS,
  FETCH_PDF,
  PDF_ERROR,
  CREATE_PDF,
  UPDATE_PDF,
  DELETE_PDF,
} from "../actions/types";

const initialState = {
  pdfs: [],
  pdfError: null,
  pdf: null,
  pdfMessage: null,
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_PDF:
      return {
        ...state,
        pdf: action.payload,
      };
    case UPDATE_PDF:
      return {
        ...state,
        pdf: action.payload,
      };
    case PDF_ERROR:
      return {
        ...state,
        pdfError: action.payload,
      };
    case DELETE_PDF:
      return {
        ...state,
        pdf: action.payload,
      };
    case FETCH_PDFS:
      return {
        ...state,
        pdfs: action.payload,
      };
    case FETCH_PDF:
      return {
        ...state,
        pdf: action.payload,
      };
    default:
      return state;
  }
}
