import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { getUserDetailsFromCookie } from "../../utils/cookies";

const AdminRoute = ({ component: Component, auth, user, ...rest }) => {
  useEffect(() => {
    const userFound = getUserDetailsFromCookie();

    if (!userFound) {
      window.location.href = window.location.origin + "/login";
    }

    if (userFound.clearanceLevel !== "admin") {
      window.location.href = window.location.origin + "/";
    }
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        return <Component {...props} />;
      }}
    />
  );
};

export default AdminRoute;

