import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Input, DatePicker, Select } from 'antd';

const Option = Select.Option;

const CoinForm = (props) => {
    const { payload, handlePayloadChange, response, type } = props;
    const [ticker, setTicker] = useState("");
    const [initialDate, setInitialDate] = useState("");
    const [risco, setRisco] = useState("Medio");
    const [info, setInfo] = useState("");
    const [proportion, setProportion] = useState(0);
    const [link, setLink] = useState("");
   
    useEffect(() => {
        if (type === "editCoin" && payload && payload.ticker){
            setRisco(payload.risco);
            setTicker(payload.ticker);
            setInitialDate(moment(payload.initialDate));
            setInfo(payload.info)
            setProportion(payload.proportion);
            setLink(payload.link);
        }
    }, [payload, response, type])

    useEffect(() => {
        if (response && response.message){
            setTicker();
            setLink('');
            setInitialDate('');
            setRisco('Medio');
            setInfo('');
            setProportion(0);
            handlePayloadChange({});
        }
    }, [handlePayloadChange, response])

    const handleChange = (e, type) => {
        if (type === 'ticker') {
            setTicker(e.target.value);
            handlePayloadChange({...payload, ticker: e.target.value});
        }

        if (type === 'info'){
            setInfo(e.target.value);
            handlePayloadChange({...payload, info: e.target.value});
        }

        if (type === 'proportion'){
            setProportion(Number(e.target.value));
            handlePayloadChange({...payload, proportion: Number(e.target.value)});
        }
        if (type === 'link') {
            setLink(e.target.value);
            handlePayloadChange({...payload, link: e.target.value});
        }
    }

    const handleDateChange = (date, dateString) => {
        setInitialDate(moment(dateString));
        handlePayloadChange({...payload, initialDate: dateString});
    }

    const handleRiscoChange = (value) => {
        setRisco(value);
        handlePayloadChange({...payload, risco: value});
    }

    return (
        <div>
            <div>
                <div>
                    <label>Ticker</label>
                    <Input 
                        value={ticker}
                        defaultValue={ticker}
                        onChange={(e) => handleChange(e, 'ticker')}
                    />
                </div>

                <div style={{marginTop: 15}}>
                    <label>Link</label>
                    <Input 
                        value={link}
                        defaultValue={link}
                        onChange={(e) => handleChange(e, 'link')}
                    />
                </div>

                <div style={{marginTop: 15}}>
                    <label>Info</label>
                    <Input 
                        value={info}
                        defaultValue={info}
                        onChange={(e) => handleChange(e, 'info')}
                    />
                </div>

                <div style={{marginTop: 15}}>
                    <label>Proportion</label>
                    <Input 
                        value={proportion}
                        defaultValue={proportion}
                        type="number"
                        onChange={(e) => handleChange(e, 'proportion')}
                    />
                </div>

                <div style={{marginTop: 15}}>
                    <label>Risco</label>
                    <div>
                        <Select defaultValue={risco} value={risco} style={{ width: "100%" }} onChange={handleRiscoChange}>
                            <Option value="Médio">Médio</Option>
                            <Option value="Alto">Alto</Option>
                            <Option value="Muito Alto">Muito Alto</Option>
                            <Option value="Extremo">Extremo</Option>
                        </Select>
                    </div>
                    
                </div>

                <div style={{marginTop: 15}}>
                    <label>InitialDate</label>
                    <DatePicker
                        onChange={handleDateChange}
                        style={{width: "100%"}}
                        value={initialDate}
                        defaultValue={initialDate}
                    />
                </div>
            </div>
        </div>
    )
}

export default CoinForm;
