import {
  GET_WEB3, 
  CREATE_WEB3, 
  DELETE_WEB3,
  WEB3_ERROR,
} from "../actions/types";

const initialState = {
  web3List: [],
  web3: null,
  web3Error: null,
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_WEB3:
      return {
        ...state,
        web3List: action.payload,
      };
    case CREATE_WEB3:
      return {
        ...state,
        web3: action.payload,
      };
    case DELETE_WEB3:
    return {
      ...state,
      web3: action.payload,
    };
    case WEB3_ERROR:
      return {
        ...state,
        web3Error: action.payload,
      };
    default:
      return state;
  }
}
