import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { createPost } from "../../store/actions/postsActions";
import { getAuthors } from "../../store/actions/authorsActions";
import { fetchPdfs } from "../../store/actions/pdfsActions";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Select,
  DatePicker,
  Spin,
  Switch,
  Upload,
} from "antd";
import Editor from "./Editor";
import { SmileOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import {
  getBase64,
  uploadImagesToFirebase,
} from "../../utils/uploadFileToFirebase";

// title summary author(s) Date Time Status Category Tags Acessos body
const { TextArea } = Input;
const { Option } = Select;
const categories = [
  { name: "Fundamentalista", value: "fundamentalista" },
  { name: "Relatórios", value: "relatorio" },
  { name: "Ferramentas", value: "ferramentas" },
  { name: "Tecnofilosofia", value: "tecnofilosofia" },
  { name: "Tutoriais", value: "tutoriais" },
  { name: "Técnico", value: "tecnico" },
  { name: "Tokens", value: "tokens" },
];

const CreatePost = ({ createPost, getAuthors, authors, fetchPdfs, pdfs }) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(true);
  const [text, setText] = useState("");
  const [thumbnail, setThumbnail] = useState(false);
  const [thumbnailURL, setThumbnailURL] = useState(null);
  const [fields, setFields] = useState([
    {
      name: "title",
      value: "",
    },
    {
      name: "shortLink",
      value: "",
    },
    {
      name: "summary",
      value: "",
    },
    {
      name: "source",
      value: "",
    },
    {
      name: "isFree",
      value: false,
    },
    {
      name: "authors",
      value: [],
    },
    {
      name: "data",
      value: "",
    },
    {
      name: "status",
      value: "",
    },
    {
      name: "category",
      value: "",
    },
    {
      name: "tags",
      value: [],
    },
    {
      name: "pdfs",
      value: [],
    },
  ]);
  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      await getAuthors();
      fetchPdfs();
      setLoading(false);
    };
    getData();
  }, [getAuthors, fetchPdfs]);

  const handleSubmit = async (values) => {
    setLoading(true);
    let fileURL = "";
    if (thumbnail) {
      fileURL = await uploadImagesToFirebase(thumbnail.file);
    } else {
      fileURL = thumbnailURL;
    }

    await createPost({ ...values, body: text, thumbnail: fileURL });
    setLoading(true);
  };

  const validateFields = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
    setLoading(false);
  };

  // const uploadthumbnail = async ({ file, onError, onProgress, onSuccess }) => {
  //   try {
  //     setLoading(true);
  //     const url = await uploadOtherFilesToFirebase(file, "thumbnail");
  //     setLoading(false);
  //     setThumbnail(url);
  //     onSuccess();
  //   } catch (error) {
  //     console.log(error);
  //     onError();
  //   }
  // };

  const handleUploadImg = (upload) => {
    getBase64(upload.file.originFileObj, (imageUrl) => {
      setThumbnail(upload);
      setThumbnailURL(imageUrl);
    });
  };

  const handleChange = (fields) => {
    setFields(fields);
  };

  return (
    <Form
      name="global_state"
      onFinish={handleSubmit}
      fields={fields}
      onFinishFailed={validateFields}
      onFieldsChange={(_, allFields) => {
        handleChange(allFields);
      }}
    >
      <Spin spinning={loading}>
        <Row gutter={24}>
          <Col span={10}>
            <Form.Item
              name="title"
              label="Title"
              rules={[{ required: true, message: "this field is required" }]}
            >
              <Input placeholder="title" />
            </Form.Item>
          </Col>
          <Col span={14}>
            <Form.Item
              name="summary"
              label="Summary"
              rules={[{ required: true, message: "this field is required" }]}
            >
              <TextArea placeholder="summary" />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item name="source" label="Fonte">
              <Input placeholder="fonte" />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item name="isFree" label="Free Content">
              <Switch />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item name="shortLink" label="Link dinâmico">
              <Input placeholder="Link dinâmico" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <label className="ant-form-item-label">Content :</label>
            <Editor
              value={text}
              onChange={(value) => {
                setText(value);
              }}
            />
          </Col>
          <Col span={12}>
            <Form.Item
              name="authors"
              label="Authors"
              rules={[{ required: true, message: "this field is required" }]}
            >
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="Please select authors"
              >
                {authors.map((author) => (
                  <Option value={author.id}>
                    {author.displayName || "No Name"}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}></Col>
          <Col span={6}>
            <Form.Item
              name="date"
              label="Date & time"
              rules={[{ required: true, message: "this field is required" }]}
            >
              <DatePicker showTime placeholder="Select Time" />
            </Form.Item>
          </Col>
          <Col span={6}></Col>
          <Col span={12}>
            <Form.Item
              name="status"
              label="Status"
              rules={[{ required: true, message: "this field is required" }]}
            >
              <Select style={{ width: "100%" }} placeholder="Please select">
                <Option value="draft">Draft</Option>
                <Option value="published">Published</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="category"
              label="Category"
              rules={[{ required: true, message: "this field is required" }]}
            >
              <Select style={{ width: "100%" }} placeholder="Please select">
                {categories.map((cat) => (
                  <Option value={cat.value} key={cat.value}>
                    {cat.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="tags"
              label="Tags"
              rules={[{ required: true, message: "this field is required" }]}
            >
              <Select
                mode="tags"
                style={{ width: "100%" }}
                placeholder="Tags Mode"
              ></Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="pdfs"
              label="Pdfs"
              rules={[{ required: false, message: "this field is required" }]}
            >
              <Select
                allowClear
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="Please select Pdfs"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {pdfs && pdfs.length > 0
                  ? pdfs.map((pdf, idx) => {
                      return (
                        <Option value={pdf.id} key={pdf.id}>
                          {pdf.name || "No Pdfs"}
                        </Option>
                      );
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Thumbnail">
              <Upload onChange={(upload) => handleUploadImg(upload)}>
                <Button>
                  <SmileOutlined type="upload" /> Click to Upload
                </Button>
              </Upload>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              style={{
                display: "block",
                marginLeft: "auto",
                width: "100px",
              }}
            >
              Create
            </Button>
          </Col>
        </Row>
      </Spin>
    </Form>
  );
};
const mapStateToProps = (state) => ({
  authors: state.authors.authors,
  pdfs: state.pdfs.pdfs,
});
export default connect(mapStateToProps, { createPost, fetchPdfs, getAuthors })(
  CreatePost
);
