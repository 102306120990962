import {
  GET_POSTS,
  GET_POST,
  ADD_POST,
  DELETE_POST,
  UPDATE_POST,
} from "./types";
import moment from "moment";
import history from "../../history";
import firebase, { firestore } from "../../config/firebase";
import placeholder from "../../assets/image-placeholder.svg";
import axios from "axios";

export const getPosts =
  (category) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const res = await firestore
      .collection("posts")
      .orderBy("date", "desc")
      .get();
    const posts = res.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    dispatch({
      type: GET_POSTS,
      payload: posts,
    });
  };

export const getPost = (id) => async (dispatch) => {
  try {
    const doc = await firestore.collection("posts").doc(id).get();
    const contentDoc = await firestore
      .collection("posts")
      .doc(id)
      .collection("content")
      .get();

    const body =
      contentDoc.docs.length > 0 ? contentDoc.docs[0].data().body : "";
    const post = {
      id: doc.id,
      ...doc.data(),
      date: moment.unix(doc.data().date),
      body,
    };

    return dispatch({
      type: GET_POST,
      payload: post,
    });
  } catch (error) {
    console.log("error", error);
    return error;
  }
};

export const createPost =
  ({
    title,
    shortLink,
    summary,
    source = "",
    authors,
    date,
    status,
    category,
    tags,
    thumbnail,
    body,
    isFree,
  }) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
      const state = getState();

      const { authors: allAuthors } = state.authors;
      authors = authors.map((author) => {
        const newAuthor = allAuthors.find((a) => a.id === author);
        if (newAuthor) {
          return {
            ...newAuthor,
          };
        } else {
          return {};
        }
      });

      const firestore = getFirestore();
      const newPost = {
        title,
        shortLink,
        summary,
        authors,
        date: date.unix(),
        status,
        category,
        tags,
        source,
        isFree: isFree || false,
        thumbnail: thumbnail || false,
      };
      const doc = await firestore.collection("posts").add(newPost);

      try {
        await firestore
          .collection("posts")
          .doc(doc.id)
          .collection("content")
          .add({ body });
      } catch (err) {
        await axios.post(
          `${process.env.REACT_APP_GOLANG_API}/api/v2/post/${doc.id}/content`,
          { content: body }
        );
      }

      const post = { id: doc.id, ...newPost, body };

      dispatch({
        type: ADD_POST,
        payload: post,
      });

      axios.put(
        `${process.env.REACT_APP_BACKEND_UPDATING_URL}/update-coins-with-posts`
      );

      axios.post(`${process.env.REACT_APP_GOLANG_API}/api/v2/short-links`, {
        name: doc.title,
        url: `https://post.paradigma.education/${shortLink}`,
        destination: `https://app.paradigma.education/feed/post/view?id=${doc.id}`,
      });

      history.push("/posts");
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  };

export const updatePost =
  (id, updPost, body) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
      const firestore = getFirestore();
      const state = getState();
      const { authors: allAuthors } = state.authors;

      updPost.authors = updPost.authors.map((author) => {
        const newAuthor = allAuthors.find((a) => a.id === author);
        if (newAuthor) {
          return {
            ...newAuthor,
          };
        } else {
          return {};
        }
      });

      updPost.date = updPost.date.unix();

      await firestore.collection("posts").doc(id).update(updPost);

      const contentDoc = await firestore
        .collection("posts")
        .doc(id)
        .collection("content")
        .get();

      contentDoc.docs[0].ref.update({ body });

      const post = { id, ...updPost, body };

      dispatch({
        type: UPDATE_POST,
        payload: post,
      });

      axios.put(
        `${process.env.REACT_APP_BACKEND_UPDATING_URL}/update-coins-with-posts`
      );

      axios.post(`${process.env.REACT_APP_GOLANG_API}/api/v2/short-links`, {
        name: post.title,
        url: `https://post.paradigma.education/${post.shortLink}`,
        destination: `https://app.paradigma.education/feed/post/view?id=${id}`,
      });

      history.push("/posts");
    } catch (error) {
      return error;
    }
  };

export const deletePost =
  (id) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
      const firestore = getFirestore();

      await firestore.collection("posts").doc(id).delete();

      dispatch({
        type: DELETE_POST,
        payload: id,
      });
    } catch (error) {
      return error;
    }
  };

export function imageHandler() {
  const input = document.createElement("input");

  input.setAttribute("type", "file");
  input.setAttribute("accept", "image/*");
  input.click();

  input.onchange = async () => {
    const file = input.files[0];

    const range = this.quill.getSelection(true);

    this.quill.insertEmbed(range.index, "image", placeholder);

    this.quill.setSelection(range.index + 1);

    const url = await uploadToFirebase(file);

    this.quill.deleteText(range.index, 1);

    this.quill.insertEmbed(range.index, "image", url);
  };
}
export const uploadToFirebase = async (data) => {
  const metadata = {
    cacheControl: "public,max-age=36000",
  };
  const storageRef = firebase.storage().ref();
  const filename = Date.now() + "-" + data.name;
  const newImage = storageRef.child("/flamelink/media/" + filename);

  await newImage.put(data, metadata);
  return await newImage.getDownloadURL();
};
