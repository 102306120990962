import { FETCH_COINS_LIST, COINS_LIST_ERROR, CREATE_STAR } from "./types";
import axios from "axios";

export const fetchCoinsList = () => async (dispatch) => {
  try {
    const responsePage0 = await axios.get(
      "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=250&page=1&sparkline=false&price_change_percentage=1h,24h,7d"

    );

    const responsePage2 = await axios.get(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/coins`
    );

    const page0 = responsePage0.data;
    const mergeResult = page0;

    return dispatch({
      type: FETCH_COINS_LIST,
      payload: mergeResult.map((el) => {
        const res = responsePage2.data.coins.filter((c) => {
          return c.symbol === el.id;
        });
        if (res.length > 0) {
          el.star = res[0].star;
          Object.assign(el, { star: res[0].star });
        }
        return el;
      }),
    });
  } catch (error) {
    return dispatch({
      type: COINS_LIST_ERROR,
      payload: error.response ? error.response.data.error : error.message,
    });
  }
};

export const createStar = (payload) => async (dispatch) => {
  try {
    const data = await axios.post(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/coins`,
      payload
    );
    dispatch({
      type: CREATE_STAR,
      payload: data.data,
    });
    return setCoinsListError("");
  } catch (error) {
    return dispatch({
      type: COINS_LIST_ERROR,
      payload: error.response ? error.response.data.error : error.message,
    });
  }
};

export const setCoinsListError = (message) => (dispatch) => {
  return dispatch({
    type: COINS_LIST_ERROR,
    payload: message,
  });
};
