import {
  GET_CURATED_PROFILES,
  CREATE_CURATED_PROFILE,
  UPDATE_CURATED_PROFILE,
  CREATE_COIN,
  UPDATE_COIN,
  DELETE_CURATED_PROFILE,
  DELETE_COIN,
} from "../actions/types";

const initialState = {
  curatedProfiles: [],
  curatedProfileCreated: null,
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CURATED_PROFILES:
      return {
        ...state,
        curatedProfiles: action.payload,
      };
    case CREATE_CURATED_PROFILE:
      return {
        ...state,
        curatedProfile: action.payload,
      };
    case UPDATE_CURATED_PROFILE:
      return {
        ...state,
        curatedProfile: action.payload,
      };
    case CREATE_COIN:
      return {
        ...state,
        coin: action.payload,
      };
    case UPDATE_COIN:
      return {
        ...state,
        coin: action.payload,
      };
    case DELETE_CURATED_PROFILE:
      return {
        ...state,
        message: action.payload,
      };
    case DELETE_COIN:
      return {
        ...state,
        message: action.payload,
      };
    default:
      return state;
  }
}

