import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Modal, Row, Table, Avatar, Input } from "antd";
import { FolderAddTwoTone } from "@ant-design/icons";
import { connect } from "react-redux";
import {
  createModule,
  deleteModule,
  fetchModule,
  fetchModules,
  searchTrack,
  setModuleError,
  updateModule,
} from "../../store/actions/trackActions";
import {
  getBase64,
  uploadOtherFilesToFirebase,
} from "../../utils/uploadFileToFirebase";
import { getVideos } from "../../store/actions/videoActions";
import DeleteModule from "./DeleteModule";
import CreateUpdateForm from "./CreateUpdateForm";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

const { Search } = Input;

const Modules = ({
  modules,
  moduleError,
  createModule,
  updateModule,
  fetchModules,
  deleteModule,
  getVideos,
  searchTrack,
  searchResult,
  searchError,
  videos,
}) => {
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("createTrack");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [name, setName] = useState("");
  const [formError, setFormError] = useState("");
  const [description, setDescription] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailURL, setThumbnailURL] = useState(null);
  const [videoIds, setVideoIds] = useState([]);
  const [moduleId, setModuleId] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchedResults, setSearchedResults] = useState([]);

  useEffect(() => {
    fetchModules();
    getVideos();
  }, [fetchModules, getVideos]);

  useEffect(() => {
    if (searchResult && searchText) {
      setSearchedResults(searchResult.modules || []);
    }

    if (!searchText || searchText === "") {
      setSearchedResults([]);
    }
  }, [searchResult, searchText]);

  const openModal = (modalType, record) => {
    setIsModalVisible(true);
    setType(modalType);
    setName(record.name);
    setDescription(record.description);
    setModuleId(record.id);
    setThumbnailURL(record.thumbnail);
    setVideoIds(
      record.videos && record.videos.length > 0
        ? record.videos.map((video) => video.id)
        : []
    );
  };

  const clearState = () => {
    setVideoIds([]);
    setName("");
    setDescription("");
    setLoading(false);
    setThumbnailURL(null);
    setThumbnail(null);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    clearState();
  };

  const handleInputChange = (event, eventType) => {
    if (eventType === "name") {
      setName(event.target.value);
    }

    if (eventType === "description") {
      setDescription(event.target.value);
    }
  };

  const handleSelectChanger = (value) => {
    setVideoIds(value);
  };

  const handleUpload = (upload) => {
    getBase64(upload.file.originFileObj, (imageUrl) => {
      setThumbnail(upload);
      setThumbnailURL(imageUrl);
    });
  };

  const handleOk = async () => {
    setLoading(true);

    if (["createModule", "editModule"].includes(type) && !name) {
      setFormError("Please provide a name for this module");
      return false;
    }

    if (type === "createModule") {
      let fileURL = "";
      if (thumbnail) {
        fileURL = await uploadOtherFilesToFirebase(thumbnail.file, "thumbnail");
      }
      const finalPayload = {
        name,
        description,
        videos: videoIds,
        thumbnail: fileURL,
      };
      await createModule(finalPayload);
    }

    if (type === "editModule") {
      let fileURL = "";
      if (thumbnail) {
        fileURL = await uploadOtherFilesToFirebase(thumbnail.file, "thumbnail");
      } else {
        fileURL = thumbnailURL;
      }
      const finalPayload = {
        name,
        description,
        videos: videoIds,
        thumbnail: fileURL,
      };

      await updateModule(finalPayload, moduleId);
    }

    if (type === "deleteModule") {
      await deleteModule(moduleId);
    }

    clearState();
    setLoading(false);
    setIsModalVisible(false);
    await fetchModules();
  };

  const onSearch = async (value) => {
    if (value) {
      await searchTrack(value);
    }
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const columns = [
    {
      title: "Thumbnail",
      dataIndex: "thumbnail",
      key: "thumbnail",
      render: (_, record) => (
        <div>
          <Avatar size="large" shape="square" src={record.thumbnail} alt="" />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.description.localeCompare(b.description),
    },
    {
      title: "Videos",
      dataIndex: "videos",
      key: "videos",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) =>
        a.videos && b.videos ? a.videos.length - b.videos.length : false,
      render: (_, record) => (
        <div>
          {record.videos && record.videos.length > 0
            ? record.videos.slice(0, 3).map((video) => {
                return <Button style={{ marginRight: 5 }}>{video.name}</Button>;
              })
            : null}
        </div>
      ),
    },
    {
      title: "CreatedAt",
      dataIndex: "createdAt",
      key: "createdAt",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (_, record) => (
        <div>
          <Row gutter={4}>
            <Col>
              <EditOutlined
                style={{ color: "#1890ff" }}
                onClick={() => openModal("editModule", record)}
              />
            </Col>

            <Col>
              <DeleteOutlined
                style={{ color: "red" }}
                onClick={() => openModal("deleteModule", record)}
              />
            </Col>
          </Row>
        </div>
      ),
    },
  ];

  return (
    <div>
      {moduleError && typeof moduleError === "string" ? (
        <div>
          <Alert type="error" message={moduleError} />
        </div>
      ) : null}

      <div>
        <h1 style={{ marginBottom: 0 }}>Modules section</h1>
        <small>Modules section</small>
      </div>

      <div style={{ marginTop: 20 }}>
        <Row gutter={4}>
          <Col>
            <Button
              icon={<FolderAddTwoTone />}
              type="primary"
              size="middle"
              onClick={() => openModal("createModule")}
            >
              Create module
            </Button>
          </Col>

          <Col>
            <Search
              value={searchText}
              placeholder="Search here.."
              onSearch={onSearch}
              style={{ width: 200 }}
              onChange={handleSearchChange}
            />
          </Col>
        </Row>
      </div>

      <div style={{ marginTop: 20 }}>
        <Table
          columns={columns}
          dataSource={
            searchText && searchedResults.length > 0
              ? searchedResults
              : Array.isArray(modules)
              ? modules
              : []
          }
        />
      </div>

      <Modal
        title={
          type === "createModule"
            ? "Create module"
            : type === "editModule"
            ? "Edit module"
            : type === "deleteModule"
            ? "Delete module"
            : "Insert something here"
        }
        visible={isModalVisible}
        onCancel={closeModal}
        footer={[
          <Button key="back" onClick={closeModal}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            danger={type === "deleteModule"}
            loading={loading}
            onClick={handleOk}
          >
            {type === "createModule"
              ? "Create"
              : type === "editModule"
              ? "Update"
              : type === "deleteModule"
              ? "Delete"
              : "Done"}
          </Button>,
        ]}
      >
        {type === "deleteModule" ? (
          <DeleteModule name={name} />
        ) : (
          <CreateUpdateForm
            name={name}
            description={description}
            handleInputChange={handleInputChange}
            formError={formError}
            moduleError={moduleError}
            videos={videos}
            videoIds={videoIds}
            handleSelectChanger={handleSelectChanger}
            thumbnail={thumbnail}
            thumbnailURL={thumbnailURL}
            handleUpload={handleUpload}
          />
        )}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  authLoaded: state.firebase.auth.isLoaded,
  modules: state.tracks.modules,
  singleModule: state.tracks.singleModule,
  moduleError: state.tracks.moduleError,
  videos: state.videos.videos,
  searchResult: state.tracks.searchResult,
  searchError: state.tracks.searchError,
});

export default connect(mapStateToProps, {
  createModule,
  updateModule,
  fetchModule,
  fetchModules,
  deleteModule,
  setModuleError,
  getVideos,
  searchTrack,
})(Modules);
