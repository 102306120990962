import {
  FETCH_TAGS,
  TAGS_ERROR,
} from "../actions/types";

const initialState = {
  tags: [],
  tagsError: null,
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_TAGS:
      return {
        ...state,
        tags: action.payload,
      };
      case TAGS_ERROR:
      return {
        ...state,
        tagsError: action.payload,
      };
    default:
      return state;
  }
}