import {
  FETCH_SHORT_LINKS,
  SHORT_LINK_ERROR,
  CREATE_SHORT_LINK,
  UPDATE_SHORT_LINK,
  DELETE_SHORT_LINK,
  FETCH_SHORT_LINK,
} from "../actions/types";

const initialState = {
  shortLinks: [],
  shortLink: null,
  shortLinkError: null,
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_SHORT_LINK: 
      return {
        ...state,
        shortLink: action.payload,
      };
    case UPDATE_SHORT_LINK: 
      return {
        ...state,
        shortLink: action.payload,
      };
    case SHORT_LINK_ERROR: 
      return {
        ...state,
        shortLinkError: action.payload,
      };
    case DELETE_SHORT_LINK: 
      return {
        ...state,
        shortLink: action.payload,
      };
    case FETCH_SHORT_LINKS:
      return {
        ...state,
        shortLinks: action.payload,
      };
    case FETCH_SHORT_LINK:
        return {
          ...state,
          shortLink: action.payload,
        };
    default:
      return state;
  }
}
