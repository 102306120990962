import {
  GET_FUNDAMENTOSS,
  GET_FUNDAMENTOS,
  ADD_FUNDAMENTOS,
  DELETE_FUNDAMENTOS,
  UPDATE_FUNDAMENTOS,
} from "../actions/types";

const initialState = {
  fundamentos: [],
  currentFUNDAMENTOS: null,
  error: null,
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_FUNDAMENTOSS:
      return {
        ...state,
        fundamentos: action.payload,
      };
    case GET_FUNDAMENTOS:
      return {
        ...state,
        currentFUNDAMENTOS: action.payload,
      };
    case ADD_FUNDAMENTOS:
      return {
        ...state,
        fundamentos: [...state.fundamentos, action.payload],
        currentFUNDAMENTOS: action.payload,
      };
    case UPDATE_FUNDAMENTOS:
      return {
        ...state,
        fundamentos: state.fundamentos.map((FUNDAMENTOS) =>
          FUNDAMENTOS.id === action.payload.id
            ? { ...FUNDAMENTOS, ...action.payload }
            : FUNDAMENTOS
        ),
        currentFUNDAMENTOS: action.payload,
      };
    case DELETE_FUNDAMENTOS:
      return {
        ...state,
        fundamentos: state.fundamentos.filter(
          (FUNDAMENTOS) => FUNDAMENTOS.id !== action.payload
        ),
      };
    default:
      return state;
  }
}
