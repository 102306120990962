import {
  FETCH_PDFS,
  FETCH_PDF,
  PDF_ERROR,
  CREATE_PDF,
  UPDATE_PDF,
  DELETE_PDF,
} from "./types";
import axios from "axios";

export const createPdf = (payload) => async (dispatch) => {
  try {
    const data = await axios.post(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/pdfs`,
      payload
    );
    dispatch({
      type: CREATE_PDF,
      payload: data.data,
    });
    return setPdfError("");
  } catch (error) {
    return dispatch({
      type: PDF_ERROR,
      payload: error.response ? error.response.data.error : error.message,
    });
  }
};

export const setPdfError = (message) => (dispatch) => {
  return dispatch({
    type: PDF_ERROR,
    payload: message,
  });
};

export const updatePdf = (payload, badgeId) => async (dispatch) => {
  try {
    const data = await axios.patch(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/pdfs/${badgeId}`,
      payload
    );
    return dispatch({
      type: UPDATE_PDF,
      payload: data.data,
    });
  } catch (error) {
    return dispatch({
      type: PDF_ERROR,
      payload: error.response ? error.response.data.error : error.message,
    });
  }
};

export const fetchPdf = (badgeId) => async (dispatch) => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/pdfs/${badgeId}`
    );
    return dispatch({
      type: FETCH_PDF,
      payload:
        data.data && data.data.data
          ? data.data.data
          : data.data
          ? data.data
          : {
              name: "",
              url: "",
            },
    });
  } catch (error) {
    return dispatch({
      type: PDF_ERROR,
      payload: error.response ? error.response.data.error : error.message,
    });
  }
};

export const fetchPdfs = () => async (dispatch) => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/pdfs`
    );
    return dispatch({
      type: FETCH_PDFS,
      payload: data.data && data.data.data ? data.data.data : data.data,
    });
  } catch (error) {
    return dispatch({
      type: PDF_ERROR,
      payload: error.response
        ? error.response.data.error
        : error.message
        ? error.message
        : error.error,
    });
  }
};

export const deletePdf = (badgeId) => async (dispatch) => {
  try {
    const data = await axios.delete(
      `${process.env.REACT_APP_GOLANG_API}/api/v2/pdfs/${badgeId}`
    );

    return dispatch({
      type: DELETE_PDF,
      payload: data.data,
    });
  } catch (error) {
    return dispatch({
      type: PDF_ERROR,
      payload: error.response ? error.response.data.error : error.message,
    });
  }
};
