import React from 'react'

const DeleteForm = ({ name }) => {
    return (
        <div>
            <p>
                Are you sure you want to delete this video({name})
            </p>
        </div>
    )
}

export default DeleteForm
