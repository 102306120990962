import {
  FETCH_BADGES,
  BADGE_ERROR,
  CREATE_BADGE,
  UPDATE_BADGE,
  DELETE_BADGE,
  FETCH_BADGE,
} from "../actions/types";

const initialState = {
  badges: [],
  badgeError: null,
  badge: null,
  badgeMessage: null,
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_BADGE:
      return {
        ...state,
        badge: action.payload,
      };
    case UPDATE_BADGE:
      return {
        ...state,
        badge: action.payload,
      };
    case BADGE_ERROR:
      return {
        ...state,
        badgeError: action.payload,
      };
    case DELETE_BADGE:
      return {
        ...state,
        badge: action.payload,
      };
    case FETCH_BADGES:
      return {
        ...state,
        badges: action.payload,
      };
    case FETCH_BADGE:
      return {
        ...state,
        badge: action.payload,
      };
    default:
      return state;
  }
}
