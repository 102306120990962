import React from "react";
import { Modal, Form, Input } from "antd";

const LinkModal = ({ visible, toggle, selectedPostID }) => {
  const link = `https://app.paradigma.education/feed/post/view?id=${selectedPostID}`;
  return (
    <Modal
      title="Sharable Link"
      visible={visible}
      onCancel={toggle}
      onOk={toggle}
      destroyOnClose
    >
      <Form.Item>
        <Input value={link} />
      </Form.Item>
    </Modal>
  );
};

export default LinkModal;
