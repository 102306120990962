import React, { useState, useEffect } from "react";
import { auth } from "./config/firebase";
import "antd/dist/antd.css";
import "./App.css";
import "react-quill/dist/quill.snow.css";
import {Route, Router} from "react-router-dom";
import history from "./history";
import { Layout } from "antd";
import Sider from "./components/layout/Sider";
import Header from "./components/layout/Header";
import Content from "./components/layout/Content";
import {connect} from "react-redux";
import {setUser, setNullUser} from "./store/actions/authActions";
import Login from "./components/Login";
import {getUserDetailsFromCookie} from "./utils/cookies";

const App = ({ setUser, authLoaded, setNullUser }) => {
  const user = getUserDetailsFromCookie();
  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = () => setShowMenu(c => !c);

  useEffect(() => {
    auth.onAuthStateChanged((newUser) => {
      if (!newUser) {
        setNullUser();
      }

      if (newUser) {
        setUser(newUser);
      }
    });
  }, [setNullUser, setUser])

  if (!authLoaded) return null;

  return (
    <Router history={history}>
      <div className="App">
        <Route exact path="/login" component={Login} />
        {window.location.pathname !== "/login" ? <Layout className="main">
          <Sider user={user ? user : {}} showMenu={showMenu}/>
          <Layout>
            <Header toggleMenu={toggleMenu} showMenu={showMenu}/>
            <Content />
          </Layout>
        </Layout> : null}
      </div>
    </Router>
  );
}

const mapStateToProps = (state) => ({
  authLoaded: state.firebase.auth.isLoaded,
});

export default connect(mapStateToProps, { setUser, setNullUser })(App);

