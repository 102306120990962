import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import {getObjectFromCookie} from "../../utils/cookies";

const PrivateRoute = ({ component: Component, auth, user, ...rest }) => {
  useEffect(() => {
    const userFound = getObjectFromCookie("_session");
    if (!userFound){
        return window.location.href = window.location.origin + "/login";
    }
  }, []);

  return (
      <Route
          {...rest}
          render={(props) => {
            return <Component {...props} />
          }}
      />
  );
};

export default PrivateRoute;