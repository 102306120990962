import React from "react";
import { Alert, Input, Select, Upload } from "antd";
import { beforeThumbnailUpload } from "../../utils/uploadFileToFirebase";
import { PlusOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";

const Option = Select.Option;

const CreateUpdateForm = ({
  formError,
  handleInputChange,
  name,
  description,
  handleSelectChanger,
  videos,
  videoIds,
  moduleError,
  thumbnailURL,
  handleUpload,
}) => {
  return (
    <div>
      {formError && typeof formError === "string" ? (
        <div style={{ marginBottom: 15 }}>
          <Alert type="error" message={formError} />
        </div>
      ) : null}

      {moduleError && typeof moduleError === "string" ? (
        <div style={{ marginBottom: 15 }}>
          <Alert type="error" message={moduleError} />
        </div>
      ) : null}

      <div>
        <label>Name</label>
        <Input
          value={name}
          defaultValue={name}
          onChange={(e) => handleInputChange(e, "name")}
        />
      </div>

      <div style={{ marginTop: 15 }}>
        <label>Description</label>
        <Input.TextArea
          rows={4}
          placeholder="Enter description here"
          value={description}
          defaultValue={description}
          onChange={(e) => handleInputChange(e, "description")}
        />
      </div>

      <div style={{ marginTop: 15, display: "none" }}>
        <label>Thumbnail</label>
        <div className="thumbnail-container">
          <ImgCrop
            grid
            rotate
            aspect={16 / 9}
            quality={0.8}
            crop={{ x: 0, y: 0 }}
          >
            <Upload
              name="thumbnail"
              listType="picture-card"
              accept="image/png, image/jpg, image/jpeg"
              showUploadList={false}
              beforeUpload={beforeThumbnailUpload}
              onChange={(upload) => handleUpload(upload)}
            >
              {thumbnailURL ? (
                <img
                  src={thumbnailURL}
                  alt="avatar"
                  style={{ width: "100%", height: "200px" }}
                />
              ) : (
                <PlusOutlined />
              )}
            </Upload>
          </ImgCrop>
        </div>
      </div>

      <div style={{ marginTop: 15 }}>
        <label>Videos</label>
        <Select
          allowClear
          mode="multiple"
          value={videoIds}
          defaultValue={videoIds}
          style={{ width: "100%" }}
          placeholder=""
          onChange={handleSelectChanger}
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {videos && videos.length > 0
            ? videos.map((video) => {
                return (
                  <Option value={video.id} key={video.id}>
                    {video.name}
                  </Option>
                );
              })
            : null}
        </Select>
      </div>
    </div>
  );
};
export default CreateUpdateForm;
