/* eslint-disable no-extend-native */
import { GET_COUPONS, GET_COUPONS_BY_CODE } from "./types";
import moment from "moment";
import { firestore } from "../../config/firebase";

// eslint-disable-next-line
export const getCouponsPurchases =
  () =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
      const firestore = getFirestore();
      const res = await firestore
        .collection("coupons_purchases")
        .orderBy("purchaseDate", "desc")
        .get();

      const coupons = res.docs
        .map((doc) => {
          let purchaseDate = "";

          if (doc.data().purchaseDate.seconds) {
            purchaseDate = moment
              .unix(doc.data().purchaseDate.seconds)
              .format("DD. MMM YYYY");
          }

          if (!doc.data().purchaseDate.seconds) {
            purchaseDate = moment(doc.data().purchaseDate).format(
              "DD. MMM YYYY"
            );
          }

          return {
            docId: doc.id,
            ...doc.data(),
            ...doc.data().coupon,
            card_cvv: "",
            card_expiration_date: "",
            card_holder_name: "",
            card_number: "",
            purchaseDate,
          };
        })
        .sort((a, b) => {
          return new Date(b.purchaseDate) - new Date(a.purchaseDate);
        });

      dispatch({
        type: GET_COUPONS,
        payload: coupons,
      });
    } catch (error) {
      console.log("error", error);
      return error;
    }
  };

export const getDates = (startDate, stopDate) => {
  var dateArray = {};
  var currentDate = moment(startDate);
  stopDate = moment(stopDate);
  while (currentDate <= stopDate) {
    dateArray[`${moment(new Date(currentDate)).format("DD. MMM YYYY")}`] = 0;
    currentDate = moment(currentDate).add(1, "days");
  }
  return dateArray;
};

export const getCouponPurchasesByCode = (id) => async (dispatch) => {
  try {
    const res = await firestore.collection("coupons_purchases").doc(id).get();

    if (!res.exists) {
      return dispatch({
        type: GET_COUPONS_BY_CODE,
        payload: null,
      });
    }

    const {
      customer,
      subscriptionPlan,
      purchaseDate,
      coupon: fetchedCoupon,
    } = res.data();

    const coupon = {
      id: res.id,
      payment_method: subscriptionPlan.paymentMethod,
      ...res.data(),
      ...fetchedCoupon,
      amount: subscriptionPlan.amount,
      email: customer && customer.email ? customer.email : null,
      purchaseDate: moment(purchaseDate).format("DD. MMM YYYY"),
    };

    return dispatch({
      type: GET_COUPONS_BY_CODE,
      payload: [coupon],
    });
  } catch (error) {
    console.log("error", error);
    return error;
  }
};
