import React, { useEffect, useState } from "react";
import { Form, Input, Button, Alert } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { Spin, Space } from "antd";
import { setUser, resetLoginError } from "../store/actions/authActions";
import firebase, { auth } from "../config/firebase";
import { getObjectFromCookie } from "../utils/cookies";

const Login = ({ form, setUser, error, resetLoginError }) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const token = getObjectFromCookie("_session");
    if (token) {
      window.location.href = window.location.origin + "/";
    }
  }, []);

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    let email;
    let password;

    form.validateFields((err, values) => {
      if (err) {
        return false;
      }
      email = values.email;
      password = values.password;
    });

    const response = await auth.signInWithEmailAndPassword(email, password);
    await setUser(response.user, true);
    setLoading(false);
  };

  const handleGoogleLogin = async () => {
    setLoading(true);
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({ prompt: "select_account" });
    const response = await auth.signInWithPopup(provider);
    await setUser(response.user, true);
    setLoading(false);
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          paddingTop: 300,
        }}
      >
        <Space size="middle">
          <Spin tip="Loading..." size="large" />
        </Space>
      </div>
    );
  }

  return (
    <div className="login_container">
      <Form onSubmit={handleSubmit} className="login-form">
        {error && (
          <Alert
            message={error}
            type="error"
            style={{
              marginBottom: "2rem",
            }}
            closable
            onClose={() => resetLoginError()}
          />
        )}
        <Form.Item
          rules={[{ required: true, message: "Please input your username!" }]}
        >
          <Input
            prefix={
              <SmileOutlined type="user" style={{ color: "rgba(0,0,0,.25)" }} />
            }
            placeholder="Email"
            type="email"
          />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "Please input your Password!" }]}
        >
          <Input
            prefix={
              <SmileOutlined type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
            }
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            style={{ width: "100%" }}
          >
            Log in
          </Button>
          <Button
            type="primary"
            onClick={handleGoogleLogin}
            className="login-form-button"
            style={{ width: "100%" }}
          >
            Log in with google
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  error: state.auth.error,
});

export default connect(mapStateToProps, {
  setUser,
  resetLoginError,
})(Login);
