import {
  LOGIN,
  LOGIN_ERROR,
  RESET_LOGIN_ERROR,
  LOGOUT,
} from "../actions/types";

const initialState = {
  error: null,
  loggedIn: false,
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return {
        error: null,
        loggedIn: true,
      };
    case LOGIN_ERROR:
      return {
        error: action.payload,
      };
    case RESET_LOGIN_ERROR:
      return {
        error: null,
      };
    case LOGOUT:
      return {
        loggedIn: false,
      };

    default:
      return state;
  }
}
